import Reactt, { useEffect, useState, useRef } from 'react';
import NumberControl from '../NumberControl';
import SelectControl from '../SelectControl';
import TextAreaControl from '../TextAreaControl';
import BinaryControl from '../BinaryControl';
import MultiSelect from '../MultiSelect';
import { updateObject, updateSubObject, getQuote, getSpine, getPapers, uploadFile } from "../../Utils";


export default function SpineGenerator({ edition, run, customer, setEdition, setRun, hasChanges, setHasChanges }) {

  const [textPapers, setTextPapers] = useState([]);
  const [coverPapers, setCoverPapers] = useState([]);
  const [spine, setSpine] = useState('');

  const getTheSpine = async () => {
    let newSpine = await getSpine(edition);
    setSpine(newSpine);
    setHasChanges(false);
  }


  const sortPapers = async () => {
    const data = await getPapers();
    setTextPapers(data['Text']);
    setCoverPapers(data['Cover']);
  };

  useEffect(() => {
    sortPapers();
  }, []);

  return (
    <div>

      <NumberControl
        value={edition.pages}
        change={(value) => {
          updateObject(edition, "pages", parseInt(value), setEdition);
        }}
        label={edition.type == 'Flat' ? 'Sheets' : 'Page Count'}
        valid={false}
        min={2}
        max={1000}
        step="2"
      />

      {
        edition.type == "Paperback" && (
          <div>
            <SelectControl
              value={edition.interiorPaper}
              change={(value) => {
                updateObject(edition, "interiorPaper", value, setEdition);
              }}
              label={"Interior Paper Stock"}
              options={[
                {
                  value: "",
                  label: "--",
                },
              ].concat(
                textPapers.map((paper) => {
                  return {
                    value: paper._id,
                    label: `${paper.name} (${paper.weight})`,
                  };
                })
              )}
            />

            <SelectControl
              value={edition.coverPaper}
              change={(value) => {
                updateObject(edition, "coverPaper", value, setEdition);
              }}
              label={"Cover Stock"}
              options={[
                {
                  value: "",
                  label: "--",
                },
              ].concat(
                coverPapers.map((paper) => {
                  return {
                    value: paper._id,
                    label: `${paper.name} (${paper.weight})`,
                  };
                })
              )}
            />

          </div>
        )
      }
      {!hasChanges && spine && <div className='estimated-price mb-large'><strong>Spine Width:</strong> {spine}"</div>}
      {hasChanges && <button className='primary linkish' onClick={getTheSpine}>Calculate Spine</button>}
    </div>
  )
}