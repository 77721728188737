import React, { useState, useEffect } from 'react';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import SelectControl from '../../components/SelectControl';
import TextAreaControl from '../../components/TextAreaControl';
import BinaryControl from '../../components/BinaryControl';
import MultiSelect from '../../components/MultiSelect';
import { updateObject, updateSubObject, getPapers } from '../../Utils';


export default function EditEdition({ edition, save, close }) {
    const [updatedEdition, setUpdatedEdition] = useState(edition);
    const [textPapers, setTextPapers] = useState([]);
    const [coverPapers, setCoverPapers] = useState([]);
    const [costConfig, setCostConfig] = useState({});


    const additions = ['design consultation', 'letterpress cover', 'color inserts', 'foil stamps', 'French flaps']


    const getConfig = async () => {
        const data = await window.CPA.makeRequest("get-cost-config", "GET");
        if (data && data._id) {
            setCostConfig(data);
        }
    };

    const sortPapers = async () => {
        const data = await getPapers();
        console.log(data);
        setTextPapers(data['Text']);
        setCoverPapers(data['Cover']);
    }

    useEffect(() => {
        sortPapers();
        getConfig();
    }, [])




    return (
        <div className='modal-container'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Editing Edition Data for {updatedEdition.title}</h3>
                </div>
                <div className='modal-body flex sb'>
                    <div>
                        <TextControl
                            value={updatedEdition.author}
                            change={(value) => {
                                updateObject(updatedEdition, "author", value, setUpdatedEdition);
                            }}
                            label={"Author(s)"}
                        />
                        <NumberControl
                            value={updatedEdition.pages}
                            change={(value) => {
                                updateObject(updatedEdition, "pages", parseInt(value), setUpdatedEdition);
                            }}
                            label={"Page Count"}
                            valid={false}
                            min={60}
                            max={1000}
                            step="1"
                        />

                        {updatedEdition.type == "Paperback" && (
                            <div>
                                <SelectControl
                                    value={updatedEdition.binding}
                                    change={(value) => {
                                        updateObject(updatedEdition, "binding", value, setUpdatedEdition);
                                    }}
                                    label={"Binding"}
                                    help={
                                        updatedEdition.binding == 'Perfect' && updatedEdition.pages < 30 ?
                                            'We recommend a minimum pagecount of 30 for perfect bound books. Consider a staplebound book.' :
                                            updatedEdition.binding == 'Staple' && updatedEdition.pages > 30 ?
                                                'We recommend a maximum pagecount of 30 for staplebound books. Consider a perfect bound book' :
                                                ''
                                    }
                                    options={[
                                        {
                                            value: "Perfect",
                                            label: "Perfect",
                                        },
                                        {
                                            value: "Staple",
                                            label: "Staple",
                                        },
                                    ]}
                                />
                                <SelectControl
                                    value={updatedEdition.interiorPaper}
                                    change={(value) => {
                                        updateObject(updatedEdition, "interiorPaper", value, setUpdatedEdition);
                                    }}
                                    label={"Interior Paper Stock"}
                                    options={[
                                        {
                                            value: "",
                                            label: "--",
                                        },
                                    ].concat(
                                        textPapers.map((paper) => {
                                            return {
                                                value: paper._id,
                                                label: `${paper.name} (${paper.weight})`,
                                            };
                                        })
                                    )}
                                />

                                <SelectControl
                                    value={updatedEdition.interiorPrinting}
                                    change={(value) => {
                                        updateObject(updatedEdition, "interiorPrinting", value, setUpdatedEdition);
                                    }}
                                    label={"Interior Printing"}
                                    options={[
                                        {
                                            value: "blackAndWhite",
                                            label: "Black & White",
                                        },
                                        {
                                            value: "fourColor",
                                            label: "Four Color",
                                        },
                                    ]}
                                />

                                <BinaryControl
                                    value={updatedEdition.fullBleed}
                                    change={(value) => {
                                        updateObject(updatedEdition, "fullBleed", value, setUpdatedEdition);
                                    }}
                                    label={"Is your interior full bleed?"}
                                />


                            </div>
                        )}
                    </div>

                    <div>
                        {updatedEdition.type == 'Paperback' && <div>
                            <SelectControl
                                value={updatedEdition.coverPaper}
                                change={(value) => {
                                    updateObject(updatedEdition, "coverPaper", value, setUpdatedEdition);
                                }}
                                label={"Cover Stock"}
                                options={[
                                    {
                                        value: "",
                                        label: "--",
                                    },
                                ].concat(
                                    coverPapers.map((paper) => {
                                        return {
                                            value: paper._id,
                                            label: `${paper.name} (${paper.weight})`,
                                        };
                                    })
                                )}
                            />

                            {costConfig.laminationOptions && (
                                <SelectControl
                                    value={updatedEdition.lamination}
                                    change={(value) => {
                                        updateObject(updatedEdition, "lamination", value, setUpdatedEdition);
                                    }}
                                    label={"Cover Lamination"}
                                    help={"Lamination is one-sided only"}
                                    options={Object.keys(costConfig.laminationOptions).map((opt) => {
                                        return {
                                            value: opt,
                                            label: opt,
                                        };
                                    })}
                                />
                            )}

                            <BinaryControl
                                value={updatedEdition.coverTwoSided}
                                change={(value) => {
                                    updateObject(updatedEdition, "coverTwoSided", value, setUpdatedEdition);
                                }}
                                label={"Cover printed on both sides?"}
                            /></div>}

                        <MultiSelect
                            classList=''
                            values={updatedEdition.customerRequests ? updatedEdition.customerRequests : []}
                            change={(value) => { updateObject(updatedEdition, "customerRequests", value, setUpdatedEdition) }}
                            choices={additions}
                            label={'I\'m interested in...'}
                            help={'Select any features you want for your project.'}
                        />
                        <TextAreaControl
                            value={updatedEdition.customerNotes}
                            change={(value) => {
                                updateObject(updatedEdition, "customerNotes", value, setUpdatedEdition);
                            }}
                            label={"Any other notes?"}
                        />
                    </div>

                </div>
                <div className='modal-options gap-small flex'>
                    <button className='primary linkish' onClick={() => { save(updatedEdition) }}>Update and Recalculate</button>
                    <button className='secondary linkish' onClick={() => { close(false) }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}


