import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import { updateObject, updateSubObject } from '../../Utils';

export default function AdminOptions({ sessionValid, roles }) {
  const [originalConfig, setOriginalConfig] = useState({});
  const [costConfig, setCostConfig] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  document.title = 'Pricing Options | Chatwin Printing'


  const getConfig = async () => {
    const data = await window.CPA.makeRequest('get-cost-config', 'GET');
    if (data && data._id) {
      setCostConfig(data);
      setOriginalConfig(data);
      setHasChanges(false);
    }
  }

  const saveConfig = async () => {
    const data = await window.CPA.makeRequest('save-cost-config', 'POST', costConfig);
    if (data.ok) {
      window.CPA.message({ type: 'success', temp: true, text: 'Options saved!', label: 'Success' });
      let newConfig = { ...costConfig };
      newConfig._rev = data.rev;
      setCostConfig(newConfig);
      setOriginalConfig(newConfig);
      setHasChanges(false);
    }

  }

  const undo = () => {
    setCostConfig(originalConfig);
    setHasChanges(false);
  }

  useEffect(() => {
    setHasChanges(true);
  }, [costConfig])


  useEffect(() => {
    getConfig();
  }, [])

  if (!sessionValid && !roles.admin) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  } else if (!roles.admin) {
    return (<div className='card-container'>You do not have permission to view this page.</div>)
  }

  return (
    <div className='card-container'>
      <div className='page-header'>
        <h1>Pricing Options</h1>
      </div>
      {costConfig._id && <div>
        <h2>General</h2>
        <NumberControl
          value={costConfig.setupFee}
          change={(value) => { updateObject(costConfig, 'setupFee', value, setCostConfig) }}
          label={'Standard Set-up Fee'}
          valid={false}
          step='.01'
          classlist={'dollar-before'}
        />
        <NumberControl
          value={costConfig.boundProofFee}
          change={(value) => { updateObject(costConfig, 'boundProofFee', value, setCostConfig) }}
          label={'Fee for a single bound proof'}
          valid={false}
          step='.01'
        />
        <NumberControl
          value={costConfig.unboundProofFee}
          change={(value) => { updateObject(costConfig, 'unboundProofFee', value, setCostConfig) }}
          label={'Fee for a single unbound proof'}
          valid={false}
          step='.01'
        />
        <NumberControl
          value={costConfig.printProofShipping}
          change={(value) => { updateObject(costConfig, 'printProofShipping', value, setCostConfig) }}
          label={'Fee for proof shipping'}
          valid={false}
          step='.01'
        />
        <NumberControl
          value={costConfig.overagePercentage}
          change={(value) => { updateObject(costConfig, 'overagePercentage', value, setCostConfig) }}
          label={'Overage Percentage'}
          valid={false}
          step='.1'
        />
        <h2>Ink Costs/Page</h2>
        <NumberControl
          value={costConfig.inkPerPage.blackAndWhite}
          change={(value) => { updateSubObject(costConfig, 'inkPerPage', 'blackAndWhite', value, setCostConfig) }}
          label={'Black and White'}
          valid={false}
          step='.0001'
        />
        <NumberControl
          value={costConfig.inkPerPage.fourColor}
          change={(value) => { updateSubObject(costConfig, 'inkPerPage', 'fourColor', value, setCostConfig) }}
          label={'Four Color'}
          valid={false}
          step='.0001'
        />
        <h2>Markup Levels</h2>
        {Object.keys(costConfig.markupLevels).map((level) =>
          <div key={level}>
            <NumberControl
              value={costConfig.markupLevels[level]}
              change={(value) => { updateSubObject(costConfig, 'markupLevels', level, value, setCostConfig) }}
              label={level}
              help={'Markup level as a percentage'}
              valid={false}
              step='.1'
            />

          </div>
        )}

        <h2>Perfect Binding Unit Costs</h2>
        {Object.keys(costConfig.perfectBindingCost).map((cost, index) =>
          <div key={cost}>
            <NumberControl
              value={costConfig.perfectBindingCost[cost]}
              change={(value) => { updateSubObject(costConfig, 'perfectBindingCost', cost, value, setCostConfig) }}
              label={`Up to ${cost}`}
              valid={false}
              step='.1'
            />

          </div>
        )}

        <h2>Staple Binding Unit Costs</h2>
        {Object.keys(costConfig.stapleBindingCost).map((cost, index) =>
          <div key={cost}>
            <NumberControl
              value={costConfig.stapleBindingCost[cost]}
              change={(value) => { updateSubObject(costConfig, 'stapleBindingCost', cost, value, setCostConfig) }}
              label={`Up to ${cost}`}
              valid={false}
              step='.1'
            />

          </div>
        )}

        <h2>Lamination Unit Costs</h2>
        {Object.keys(costConfig.laminationOptions).map((opt, index) =>
          <div key={opt}>
            <NumberControl
              value={costConfig.laminationOptions[opt]}
              change={(value) => { updateSubObject(costConfig, 'laminationOptions', opt, value, setCostConfig) }}
              label={opt}
              valid={false}
              step='.01'
            />

          </div>
        )}
        <h2>Max Print Runs for Self-Service Quote</h2>
        {Object.keys(costConfig.maxRuns).map((run, index) =>
          <div key={run}>
            <NumberControl
              value={costConfig.maxRuns[run]}
              change={(value) => { updateSubObject(costConfig, 'maxRuns', run, value, setCostConfig) }}
              label={run}
              valid={false}
              step='.01'
            />

          </div>
        )}
      </div>}
      <div className='flex gap-med'><button className='primary' onClick={saveConfig}>Save Changes</button>
        {hasChanges && <button className='secondary' onClick={undo}>Discard Changes</button>}
      </div>
    </div>
  )
}
