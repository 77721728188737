import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import FileControl from '../../components/FileControl';
import moment from 'moment';
import EditEdition from './edition';
import EditRun from './run.js';
import ShipRun from './ship.js';
import { getQuote, getSpine, uploadFile, sendUserInvite, sendEmail, downloadFile, getDateOrderedMaybe } from '../../Utils.js';

export default function AdminProjectEdit({ sessionValid, roles }) {
    const [project, setProject] = useState({});
    const [editingEdition, setEditingEdition] = useState(false);
    const [editingRun, setEditingRun] = useState(false);
    const [addingCost, setAddingCost] = useState(false);
    const [viewingBreakdown, setViewingBreakdown] = useState(false);
    const [shippingOrder, setShippingOrder] = useState(false);
    const [debug, setDebug] = useState(false);
    const [uploading, setUploading] = useState(false);
    const { id } = useParams();
    const [showDeleted, setShowDeleted] = useState(false);
    const [editingDates, setEditingDates] = useState(false);

    document.title = 'Print Project | Chatwin Printing'


    const getProject = async () => {
        const data = await window.CPA.makeRequest(`project?id=${id}`, 'GET');
        if (data) {
            setProject(data);
            document.title = `${data.editionData.title} | Chatwin Printing`;

        }
    }

    useEffect(() => {
        if (window.location.search.includes('?debug')) {
            setDebug(true);
        }
    }, []);

    const editionFiles = useRef(null);

    const saveEditionData = async (newEdition) => {
        let quote = await getQuote(project, newEdition, project.customerData);
        if (newEdition.type == 'paperback') {
            newEdition.spine = await getSpine(newEdition);
        }
        let eddata = await window.CPA.makeRequest('save-edition', 'POST', newEdition);
        delete quote.customerData;
        delete quote.editionData;
        let request = await window.CPA.makeRequest('save-run', 'POST', quote);
        setEditingEdition(false);
        getProject();
    }

    const uploadSelected = async () => {
        setUploading(true);
        window.CPA.message({ type: 'info', label: 'FYI', text: 'Hang tight! We are uploading your files...', temp: true });

        let newFiles = project.editionData.files ? [...project.editionData.files] : [];
        let toUpload = editionFiles.current.files;
        for (let i = 0; i < toUpload.length; i++) {
            let file = await uploadFile(toUpload[i], project.editionData.title);
            if (file.status == 'good') {
                newFiles.push(file);

            }
        }
        setUploading(false);
        window.CPA.message({ type: 'success', label: 'success', text: 'Your files have finished uploading.', temp: true });

        editionFiles.current.value = [];
        let newEdition = { ...project.editionData };
        newEdition.files = newFiles;
        let eddata = await window.CPA.makeRequest('save-edition', 'POST', newEdition);
        getProject();

    }

    const deleteFile = async (index, deletedValue) => {
        let newFiles = [...project.editionData.files];
        newFiles[index].deleted = deletedValue;
        let newEdition = { ...project.editionData };
        newEdition.files = newFiles;
        let eddata = await window.CPA.makeRequest('save-edition', 'POST', newEdition);
        getProject();
    }

    const saveRunData = async (newRun) => {
        let quote = await getQuote(newRun, project.editionData, project.customerData);
        delete quote.customerData;
        delete quote.editionData;
        let request = await window.CPA.makeRequest('save-run', 'POST', quote);
        setEditingRun(false);
        getProject();
    }

    const changeStatus = async (status) => {
        let therun = { ...project };
        if (!therun.events) {
            therun.events = [];
        }
        let theevent = {
            type: 'statusChange',
            description: `Project status changed to ${status} by ${window.CPA.user.name}`,
            user: window.CPA.user.id,
            date: new Date().toString()
        }
        therun.events.push(theevent);
        delete therun.editionData;
        delete therun.customerData;
        therun.status = status;
        let request = await window.CPA.makeRequest('save-run', 'POST', therun);
        let message = '';
        let subject = '';
        if (status == 'Proofs') {
            if (therun.proofType == 'digital') {
                subject = `Your proofs are ready to review for ${project.editionData.title}`;
                message = `Your proofs have been uploaded and are ready to review <a href='https://chatwinprinting.com/account/project/${project.editionData._id}'>here</a>.`;
            } else if (therun.proofType == 'printedShipped') {
                subject = `Your proofs for ${project.editionData.title} have shipped`;
                message = `This is to let you know that we have overnighted your proofs via UPS. Once you're all set, you can approve them <a href='https://chatwinprinting.com/account/project/${project.editionData._id}'>here</a>.`;
            } else {
                subject = `Your proofs for ${project.editionData.title} are ready`;
                message = `Your proofs are ready to review at our Seattle print studio. Please make arrangements to review them Monday-Friday, 10am to 4pm at: 569 Occidental Avenue S, Seattle, WA 98104.`;
            }
        } else if (status == 'In Production') {
            message = `We just wanted to let you know that your project is now in production`;
            subject = `We've started work on ${project.editionData.title}`;
        } else if (status == 'Ready') {
            message = `Your order is now ready to pick up at our Seattle print studio. Please make arrangements to pick it up Monday-Friday, 10am to 4pm at: 569 Occidental Avenue S, Seattle, WA 98104.`;
            subject = `${project.editionData.title} is ready for pickup`;
        }
        sendEmail(project.customerData.email, message, subject)
        getProject();
    }

    const markAsReviewed = async () => {
        let therun = { ...project };
        delete therun.editionData;
        delete therun.customerData;
        if (!therun.events) {
            therun.events = [];
        }
        let theevent = {
            type: 'statusChange',
            description: `${window.CPA.user.name} reviewed and approved the updated quote.`,
            user: window.CPA.user.id,
            date: new Date().toString()
        }
        therun.events.push(theevent);
        sendEmail(project.customerData.email, `We've looked over your project, adjusted the quote, and approved it. You can <a href="https://chatwinprinting.com/account/project/${project.editionData._id}">review it and place an order here</a>.`, `Your quote for ${project.editionData.title} is ready for you to review!`)
        therun.needsReview = false;
        let request = await window.CPA.makeRequest('save-run', 'POST', therun);
        getProject();

    }

    const proofsKey = {
        'digital': 'Digital Proofs',
        'printedShipped': 'Unbound proofs shipped',
        'printedInPerson': 'Customer will review in-person'
    };

    const maybeJoshBypass = async (e) => {
        if (e.altKey && e.ctrlKey) {
            let therun = { ...project };
            if (!therun.events) {
                therun.events = [];
            }
            let theevent = {
                type: 'statusChange',
                description: `${project.customerData.name} placed an order.`,
                user: window.CPA.user.id,
                date: new Date().toString()
            }
            therun.events.push(theevent);
            delete therun.editionData;
            delete therun.customerData;
            therun.status = 'Ordered';
            therun.proofType = 'printedInPerson';
            therun.needShipping = false;
            let request = await window.CPA.makeRequest('save-run', 'POST', therun);
            sendEmail(null, `An admin has placed an order. You can view it <a href="https://chatwinprinting.com/account/project/${project.editionData._id}">here</a>.`, `New order from ${project.customerData.name}`)
        }
    }


    useEffect(() => {
        getProject();
    }, []);

    if (!project._id || !project.customerData) {
        return (<div></div>)
    }

    if (!sessionValid && !roles.admin) {
        return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
    } else if (!roles.admin) {
        return (<div className='card-container'>You do not have permission to view this page.</div>)
    }

    return (
        <div className='card-container'>
            <div className='page-header'>
                <div>
                    <div>Printing for <Link to={`/admin/customer/${project.customerData._id}`}>{project.customerData.organization ? project.customerData.organization : project.customerData.name}</Link> | Created on {moment(project.created).format('LLL')}</div>
                    <h1>{project.editionData.title}</h1>
                    {project.editionData.author !== '' && <div>by {project.editionData.author}</div>}
                </div>
            </div>

            <div className={`step-tracker step-tracker--large flex smaller mb-large`}>
                <div className={`step ${project.status == 'Quote' ? `active` : 'inactive'}`}>Quote {project.status == 'Quote' && project.needsReview && <span className='att'>!</span>}</div>
                <div className={`step ${project.status == 'Ordered' ? `active` : 'inactive'}`} onClick={maybeJoshBypass}>Ordered</div>
                {project.status == 'Ordered' && <div className={`step ${project.status == 'Proofs' ? `active` : 'inactive linkish'}`} onClick={() => {
                    changeStatus('Proofs');
                }}>Proofs</div>}
                {project.status !== 'Ordered' &&
                    <div className={`step ${project.status == 'Proofs' ? `active` : 'inactive'}`}>Proofs</div>
                }
                <div className={`step ${project.status == 'Approved' ? `active` : 'inactive'}`}>Approved</div>


                {project.status == 'Approved' && <div className={`step inactive linkish'}`} onClick={() => {
                    changeStatus('In Production');
                }}>In Production</div>}
                {project.status !== 'Approved' &&
                    <div className={`step ${project.status == 'In Production' ? `active` : 'inactive'}`}>In Production</div>
                }

                {project.amountPaid < project.total &&
                    <div className={`step ${project.status == 'Pending' ? `active` : 'inactive linkish'}`} onClick={() => {
                        changeStatus('Pending');
                    }}>Pending</div>
                }

                {project.needShipping && (project.status == 'In Production' || project.status == 'Approved' || project.status == 'Pending') && <div className={`step ${project.status == 'Shipped' ? `active` : 'inactive linkish'}`} onClick={() => {
                    setShippingOrder(true);
                }}>Shipped</div>}

                {project.needShipping && (project.status !== 'In Production' && project.status !== 'Approved') && <div className={`step ${project.status == 'Shipped' ? `active` : 'inactive '}`}>Shipped</div>}

                {!project.needShipping &&
                    <div className={`step ${project.status == 'Ready' ? `active` : 'inactive linkish'}`} onClick={() => {
                        changeStatus('Ready');
                    }}>Ready</div>
                }
            </div>

            {debug &&
                <div className='mb-large'>
                    <h3>Debug Info</h3>
                    <div>Edition: {project.editionData._id}</div>
                    <div>Customer: {project.customerData._id}</div>
                </div>
            }

            {project.needsReview &&
                <div className='mb-large'>
                    <p><span className='bold'>This project needs review</span></p>
                    {project.reviewMessage && <p>{project.reviewMessage}</p>}
                    {project.editionData.customerRequests && <p><span className='bold'>Customer interested in:</span> {project.editionData.customerRequests.join(', ')}</p>}
                    {project.editionData.customerNotes && <p><span className='bold'>Customer says</span>: "{project.editionData.customerNotes}"</p>}
                    {!project.customerData.new && project.status == 'Quote' && <button className='primary' onClick={() => { markAsReviewed() }}>Mark as Reviewed</button>}
                    {project.customerData.new && <p><Link to={`/admin/customer/${project.customerData._id}`}>Customer</Link> hasn't created an account yet. <a onClick={() => { sendUserInvite(project.customerData.email) }}>Send an Invite</a></p>}
                </div>
            }
            <div className='flex sb'><h3>Dates</h3></div>
            <div className='table-row'>
                <div className='flex-2'>Date Ordered</div>
                <div className='flex-1'>{getDateOrderedMaybe(project)}</div>
            </div>
            <div className='table-row'>
                <div className='flex-2'>Date Requested</div>
                <div className='flex-1'>{project.dateRequested}</div>
            </div>
            <div className='table-row mb-med'>
                <div className='flex-2'>Order Ready</div>
                <div className='flex-1'>{project.readyDate}</div>
            </div>
            <div className='flex sb'><h3>Edition Details</h3><a onClick={() => setEditingEdition(true)}>Edit</a></div>
            <div className='table-row'>
                <div className='flex-2'>Project Type</div>
                <div className='flex-1'>{project.editionData.type}</div>
            </div>
            {
                project.editionData.type == 'Paperback' &&
                <div>
                    <div className='table-row'>
                        <div className='flex-2'>Binding</div>
                        <div className='flex-1'>{project.editionData.binding}</div>
                    </div>

                    <div className='table-row'>
                        <div className='flex-2'>Interior Printing</div>
                        <div className='flex-1'>
                            {project.editionData.interiorPrinting == 'blackAndWhite' ? 'Black & White' : 'Full Color'} on {project.editionData.interiorPaperData.weight} {project.editionData.interiorPaperData.name}
                        </div>
                    </div>
                    <div className='table-row'>
                        <div className='flex-2'>Cover Printing</div>
                        <div className='flex-1'>
                            Full-color {project.editionData.coverTwoSided ? 'two-sided' : 'one-sided'} on {project.editionData.coverPaperData.weight} {project.editionData.coverPaperData.name}
                        </div>
                    </div>
                </div>
            }

            <div className='table-row'>
                <div className='flex-2'>Trim</div>
                <div className='flex-1'>{project.editionData.trim}" {project.editionData.type !== 'Flat' && `(w/ ${project.editionData.spine}" spine)`}</div>
            </div>
            <div className='table-row'>
                <div className='flex-2'>Page Count</div>
                <div className='flex-1'>{project.editionData.pages} {project.type == 'Paperback' || (project.type == 'Flat' && project.editionData.flatTwoSided) ? 'duplexed' : ''}</div>
            </div>
            <div className='table-row'>
                <div className='flex-2'>Lamination</div>
                <div className='flex-1'>{project.editionData.lamination}</div>
            </div>
            {
                project.editionData.additionalCosts &&
                <div className='table-row'>
                    <div className='flex-2'>Custom Add-Ons</div>
                    <div className='flex-1'>{project.editionData.additionalCosts.map((cost) => {
                        return cost.description
                    }).join(', ')}</div>
                </div>
            }

            <div className='flex sb mt-large'><h3>Project Files</h3></div>
            {project.editionData.files &&
                project.editionData.files.map((file, index) => {
                    if (!file.deleted || showDeleted) {
                        return (
                            <div className='table-row' key={file.file}>
                                <div className='flex-2'>
                                    <div>{file.name}</div>
                                    <div className='smaller'>Uploaded by {file.user} {moment(file.uploaded).format('LLL')}</div>
                                </div>
                                <div className='flex-1'><a onClick={() => { downloadFile(file.file, true) }}>Download</a></div>
                                <div className='flex-1'>
                                    {!file.deleted && <a className='deleteLink' onClick={() => { deleteFile(index, true) }}>Delete</a>}
                                    {file.deleted && <a onClick={() => { deleteFile(index, false) }}>Restore</a>}
                                </div>
                            </div>
                        )
                    }
                }
                )
            }
            <div className='fileToggle'><a className='smallLink' onClick={() => { setShowDeleted(!showDeleted) }}>{showDeleted ? 'Hide deleted files' : 'Show deleted files'}</a></div>
            <FileControl
                classlist=''
                help={''}
                valid={false}
                reference={editionFiles}
                multiple={true}
                disabled={uploading}
                image={true}
                accept="image/png, image/jpeg, application/pdf"
            />

            <button className='primary' disabled={uploading} onClick={uploadSelected}>Upload</button>



            <div className='flex sb mt-large basic-header'><h3>Print Run Details</h3><a onClick={() => setEditingRun(true)}>Edit</a></div>
            <div className='table-row'>
                <div className='flex-2'>Copies</div>
                <div className='flex-1'>{project.quantity}</div>
            </div>
            <div className='table-row'>
                <div className='flex-2'>First Run?</div>
                <div className='flex-1'>{project.firstRun ? 'Yes' : 'No'}</div>
            </div>
            <div className='table-row'>
                <div className='flex-2'>Proofs</div>
                <div className='flex-1'>{proofsKey[project.proofType]}</div>
            </div>
            <div className='table-row'>
                <div className='flex-2'>Run Will Be Shipped?</div>
                <div className='flex-1'>{project.needShipping ? 'Yes' : 'No'}</div>
            </div>
            <div className='table-row'>
                <div className='flex-2'>Address</div>
                <div className='flex-1'>
                    {(!project.shippingAddress || project.proofType == 'digital' || !project.needShipping) ?
                        <span>n/a</span> :
                        <div>
                            <div>{project.shippingAddress.to}</div>
                            {project.shippingAddress.attn && <div>{project.shippingAddress.attn}</div>}
                            <div>{project.shippingAddress.line1}</div>
                            <div>{project.shippingAddress.line2}</div>
                            <div>{project.shippingAddress.city}, {project.shippingAddress.state} {project.shippingAddress.postalCode}</div>
                        </div>
                    }
                </div>
            </div>

            <div className='flex sb mt-large basic-header'><h3>Timeline</h3></div>
            <ul>
                {project.events && project.events.map((event) => {
                    return (
                        <li key={event.date}>{event.description}<div className='smaller'>on {moment(event.date).format('LLL')}</div></li>
                    )
                })}
            </ul>



            {/* {addingCost &&
                <div>

                </div>
            }

        <button className='primary' onClick={() => setAddingCost(false)}>Add Extra Cost</button>*/}

            <div className='flex sb basic-header mt-large'>
                <h3>Quote Details</h3>
                <a onClick={() => { setViewingBreakdown(!viewingBreakdown) }}>{viewingBreakdown ? 'Hide' : 'View'}</a>
            </div>
            {
                viewingBreakdown && <div>
                    <div>
                        {project.quoteBreakdown.map((line) =>
                            <div className='table-row' key={line.code}>
                                <div className='flex-2'>{line.description}</div>
                                <div className='flex-1'>${line.total.toFixed(2)}</div>
                            </div>
                        )}

                        <div className='table-row'>
                            <div className='flex-2'>Date Quoted</div>
                            <div className='flex-1'>
                                {moment(project.dateQuoted).format('LLL')}
                                {(project.status == 'Quote' || project.status == 'Review') && <div className='smaller'>Expires on {moment(project.quoteExpires).format('LLL')}</div>}
                            </div>
                        </div>
                        <div className='table-row mb-24'>
                            <div className='flex-2'><strong>Quoted Subtotal</strong></div>
                            <div className='flex-1'><strong>${project.subtotal.toFixed(2)}</strong></div>
                        </div>
                        {project.proofCost > 0 &&
                            <div className='table-row mb-24'>
                                <div className='flex-2'><strong>Proof Cost</strong></div>
                                <div className='flex-1'><strong>${project.proofCost.toFixed(2)}</strong></div>
                            </div>
                        }
                        {project.proofShipping > 0 &&
                            <div className='table-row mb-24'>
                                <div className='flex-2'><strong>Proof Shipping</strong></div>
                                <div className='flex-1'><strong>${project.proofShipping.toFixed(2)}</strong></div>
                            </div>
                        }
                        {project.shipping > 0 &&
                            <div className='table-row mb-24'>
                                <div className='flex-2'><strong>Shipping</strong></div>
                                <div className='flex-1'><strong>${project.shipping.toFixed(2)}</strong></div>
                            </div>
                        }
                    </div>
                </div>
            }
            <div className='table-row mb-24'>
                <div className='flex-2'><strong>Total</strong></div>
                <div className='flex-1'><strong>${project.total.toFixed(2)}</strong></div>
            </div>
            {project.amountPaid && <div className='table-row mb-24'>
                <div className='flex-2'><strong>Amount Paid</strong></div>
                <div className='flex-1'><strong>${project.amountPaid.toFixed(2)}</strong></div>
            </div>}
            {project.amountPaid && <div className='table-row mb-24'>
                <div className='flex-2'><strong>Oustanding</strong></div>
                <div className='flex-1'><strong>${(project.total - project.amountPaid).toFixed(2)}</strong></div>
            </div>}
            {editingEdition && <EditEdition edition={project.editionData} save={saveEditionData} close={setEditingEdition} />}
            {editingRun && <EditRun run={project} edition={project.editionData} save={saveRunData} close={setEditingRun} />}
            {shippingOrder && <ShipRun run={project} edition={project.editionData} save={saveRunData} close={setShippingOrder} />}

        </div >
    )
}
