import React, { useState, useEffect } from 'react';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import SelectControl from '../../components/SelectControl';
import BinaryControl from '../../components/BinaryControl';
import { updateObject, updateSubObject, sendEmail } from '../../Utils';


export default function ShipRun({ run, edition, save, close }) {
    const [updatedRun, setUpdatedRun] = useState(run);

    const saveShipped = () => {
        updatedRun.status = 'Shipped';
        updatedRun.shipDate = new Date().toString();
        if (!updatedRun.events) {
            updatedRun.events = [];
        }
        updatedRun.events.push({
            type: 'statusChange',
            description: `Status changed to Shipped by ${window.CPA.user.name}`,
            user: window.CPA.user.id,
            date: new Date().toString()
        });
        let data = {
            to: window.CPA.user.email,
            message: `We've shipped your order for ${edition.title}. Your tracking number(s) are: <a href="https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${updatedRun.shippingTracking}">${updatedRun.shippingTracking}</a>`,
            subject: `Your order of ${edition.title} has shipped!`,
        }
        sendEmail(data.to, data.message, data.subject);
        save(updatedRun);
        close(false);
    }


    return (
        <div className='modal-container'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Shipping {edition.title}</h3>
                </div>
                <div className='modal-body flex sb'>

                    <TextControl
                        value={updatedRun.shippingTracking}
                        change={(value) => {
                            updateObject(updatedRun, "shippingTracking", value, setUpdatedRun);
                        }}
                        label={`Tracking Number`}
                        valid={false}
                        type="text"
                        bypass={false}
                        description={""}
                    />

                </div>
                <div className='modal-options'>
                    <button className='primary' onClick={saveShipped}>Mark as Shipped</button>
                    <button className='secondary' onClick={() => { close(false) }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}


