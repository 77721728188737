import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from './DarkBG_1.svg';
import './style.css';

export default function Header({ sessionValid, email, name, roles, checkAuth }) {
  const [mobileDisplay, setMobileDisplay] = useState(false);
  const logout = () => {
    localStorage.setItem('cpaToken', '');
    checkAuth();
  }

  const displayEmail = (email) => {
    if (email.length > 24) {
      return email.substring(0, 10) + '...' + email.substring(email.length - 10, email.length);
    } else {
      return email;
    }
  }

  const maybeClose = (e) => {
    if (e.target.tagName == 'A') {
      setMobileDisplay(false);
    }
  }

  return (
    <header className={`${mobileDisplay ? 'show-mobile' : 'hide-mobile'}`} onClick={maybeClose}>
      <div className='header-inner'>
        <div className='top'>
          <div className='logo-container'>
            <Link aria-label='Chatwin Printing home' className='logo' to='/'><Logo /></Link>
          </div>
          <div className='mobile-menu linkish' onClick={() => { setMobileDisplay(!mobileDisplay) }}>Menu</div>
          <div className='menus'>
            <div className='main-menu'>
              <Link className='menu-item' to='/get-a-printing-quote'>Get a Printing Quote</Link>
              <a className='menu-item' href='https://www.chatwinbooks.com/about' target='_blank'>About Chatwin</a>
              {!sessionValid && <Link className='menu-item' to='/login'>Login</Link>}
            </div>
            {sessionValid && <div className='main-menu logged-in'>
              <div className='menu-user'>
                <div><Link to='/account'><strong>{name}</strong></Link></div>
                <div className='smaller fainter-one'>{displayEmail(email)}</div>
              </div>
              {roles.admin && <Link className='menu-item' to='/admin/all-projects'>Projects</Link>}
              {roles.admin && <Link className='menu-item' to='/admin/project-kanban'>Project Board</Link>}
              {roles.admin && <Link className='menu-item' to='/admin/all-customers'>Customers</Link>}
              {roles.admin && <Link className='menu-item' to='/admin/pricing-options'>Pricing Options</Link>}
              {roles.admin && <Link className='menu-item' to='/admin/papers'>Papers</Link>}
              {roles.admin && <Link className='menu-item' to='/admin/other-options'>Other Options</Link>}
              {roles.admin && <Link className='menu-item' to='/admin/quote-calculator'>Quote Calculator</Link>}
              {roles.admin && <Link className='menu-item' to='/admin/spine-calculator'>Spine Calculator</Link>}
              <Link className='menu-item' to='/account'>My Account</Link>
              <Link className='menu-item' to='/login' onClick={logout}>Log Out</Link>

              {roles.admin && roles.customer && <div className='menu-divider'></div>}
              {roles.customer && <Link className='menu-item' to='/account/new-project'>New Project +</Link>}
              {roles.customer && <Link className='menu-item' to='/account/my-projects'>My Projects</Link>}
            </div>}
          </div>
        </div>
        <div className='copyright'>
          <div>&copy; {new Date().getFullYear()} Chatwin Books.</div>
        </div>
      </div>
    </header>
  )
}
