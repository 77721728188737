import React, { useState, useEffect, useRef } from 'react';
import TextControl from '../../components/TextControl';
import SelectControl from '../../components/SelectControl';
import BinaryControl from '../../components/BinaryControl';
import FileControl from '../../components/FileControl';
import { updateObject, getShippingTotal, uploadFile, Loading, ISODateNow } from '../../Utils';
import PaymentForm from './checkout.js';
import { states } from './states.js';


export default function PlaceOrder({ edition, run, customer, save, close, getProject, hasFiles }) {

  const [thisRun, setThisRun] = useState({});
  const [costConfig, setCostConfig] = useState({});
  const [calculatingShipping, setCalculatingShipping] = useState(false);
  const [addressHasChanges, setAddressHasChanges] = useState(false);
  const [shipping, setShipping] = useState(0);
  const [proofShipping, setProofShipping] = useState(0);
  const [tax, setTax] = useState(0);
  const [newEditionFiles, setNewEditionFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [collecting, setCollecting] = useState(false);
  const [address, setAddress] = useState({
    to: '',
    attn: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
  });

  const [step, setStep] = useState(0);

  useEffect(() => {
    console.log(customer);
    if (customer && customer.address && customer.address.to) {
      setAddress(customer.address);
    }
  }, [customer])

  const editionFiles = useRef(null);

  const getRun = async () => {
    const data = await window.CPA.makeRequest(`run?id=${run}`, 'GET');
    setThisRun(data);
  }

  const getCostConfig = async () => {
    const data = await window.CPA.makeRequest("get-cost-config", "GET");
    setCostConfig(data);
  }

  useEffect(() => {
    getRun();
    getCostConfig();
  }, []);

  useEffect(() => {
    if (!thisRun.proofType || thisRun.proofType == '') {
      updateObject(thisRun, "proofType", 'printedShipped', setThisRun);
    }
  }, [thisRun])

  const uploadSelected = async () => {
    setUploading(true);
    let newFiles = [...newEditionFiles];
    let toUpload = editionFiles.current.files;
    for (let i = 0; i < toUpload.length; i++) {
      let file = await uploadFile(toUpload[i]);
      if (file.status == 'good') {
        newFiles.push(file);
      }
    }
    setNewEditionFiles(newFiles);
    setUploading(false);
    editionFiles.current.value = [];
  }

  const getTax = () => {
    let taxable = thisRun.subtotal;
    if (thisRun.needShipping && address.state == 'WA') {
      taxable += proofShipping;
      taxable += shipping;
    }
    setTax(taxable * .1025);
  }

  useEffect(() => {
    getTax();
  }, [thisRun, proofShipping, shipping, address])

  const sendSuccess = async () => {
    setStep(4);
    // Update the edition with the files!
    const newEd = { ...edition };
    newEd.files = newEditionFiles;
    delete newEd.runs;
    let data = await window.CPA.makeRequest('save-edition', 'POST', newEd);
    let newRun = { ...thisRun };
    newRun.status = 'Ordered';
    newRun.dateOrdered = ISODateNow();
    newRun.shipping = shipping;
    newRun.proofShipping = proofShipping;
    newRun.shippingAddress = address;
    newRun.amountPaid = getTotal();
    newRun.total = getTotal();
    newRun.paid = true;
    if (!newRun.events) {
      newRun.events = [];
    }
    newRun.events.push({
      type: 'payment',
      description: `${window.CPA.user.name} paid $${newRun.amountPaid}`,
      user: window.CPA.user.id,
      date: new Date().toString()
    });
    newRun.events.push({
      type: 'statusChange',
      description: `${window.CPA.user.name} has changed the status to Ordered`,
      user: window.CPA.user.id,
      date: new Date().toString()
    });
    let rundata = await window.CPA.makeRequest('save-run', 'POST', newRun);
    getProject();
    close(false);
    let email = {
      subject: `New order from ${customer.name}.`,
      message: `Hey Team, We have a new order from ${customer.organization ? customer.organization : customer.name}. You can view the print project here <a href="https://chatwinprinting.com/admin/project/${newRun._id}">here</a>.`
    }
    window.CPA.makeRequest('send-email', 'POST', email);
  }

  const removeFile = (index) => {
    let files = [...newEditionFiles];
    files.splice(index, 1);
    setNewEditionFiles(files);
  }

  const getShipping = async () => {
    if (!address.postalCode || !address.to || !address.line1 || !address.city || !address.state) {
      window.CPA.message({ type: 'error', temp: true, text: 'Please fill out all required address fields', label: 'Error' });
      return;
    }
    setCalculatingShipping(true);
    let rate = await getShippingTotal(thisRun, edition, address.postalCode);
    console.log(rate);
    setShipping(rate);
    setCalculatingShipping(false);
    setAddressHasChanges(false);
  }

  const getTotal = () => {
    let t = thisRun.subtotal * 1.1025;
    if (thisRun.needShipping && shipping > 0) {
      t += shipping;
    }
    if (address.state == 'WA') {
      t += (shipping * .1025)
    }
    if (thisRun.proofType == 'printedShipped') {
      t += costConfig['printProofShipping'] * .1025;
    }
    return t;
  }

  useEffect(() => {
    setAddressHasChanges(true);
  }, [address])

  useEffect(() => {
    setProofShipping(thisRun.proofType == 'printedShipped' ? costConfig['printProofShipping'] : 0)
  }, [thisRun])

  return (
    <div className='modal-container'>
      <div className='modal-content'>
        <div className='modal-header flex sb'>
          <h3>Place Print Order</h3>
          <div className='step-tracker flex'>
            <div className={`step ${step === 0 || step == 4 ? 'active' : 'inactive'}`}>Proofs</div>
            <div className={`step ${step === 1 || step == 4 ? 'active' : 'inactive'}`}>Shipping</div>
            <div className={`step ${step === 2 || step == 4 ? 'active' : 'inactive'}`}>Files</div>
            <div className={`step ${step === 3 || step == 4 ? 'active' : 'inactive'}`}>Payment</div>
          </div>
        </div>
        <div className='modal-body flex sb'>
          <div className='half'>


            {step === 0 &&
              <div>
                <SelectControl
                  value={thisRun.proofType ? thisRun.proofType : 'printedShipped'}
                  change={(value) => {
                    updateObject(thisRun, "proofType", value, setThisRun);
                  }}
                  label={"How would you like your proofs?"}
                  options={[
                    {
                      value: "printedShipped",
                      label: "Printed (Shipped UPS Next-Dar Air)",
                    },
                    {
                      value: "printedInPerson",
                      label: "Printed (review at print shop in Seattle)",
                    }
                  ]}
                />

                <div className='modal-options'>
                  <button className='secondary' onClick={() => { setStep(1) }}>Next: Shipping</button>
                </div>
              </div>
            }

            {step === 1 &&
              <div>
                <BinaryControl
                  value={thisRun.needShipping}
                  change={(value) => {
                    updateObject(thisRun, "needShipping", value, setThisRun);
                  }}
                  help={'Otherwise, orders will be available for pickup at our headquarters in Seattle'}
                  label={"Will your order need to be shipped to you?"}
                />

                {thisRun.needShipping &&
                  <div>
                    <h3>Shipping Address</h3>
                    <TextControl
                      value={address.to}
                      change={(value) => {
                        updateObject(address, "to", value, setAddress);
                      }}
                      label={`To`}
                      valid={false}
                      type="text"
                      required={true}
                      bypass={false}
                      description={""}
                    />
                    <TextControl
                      value={address.attn}
                      change={(value) => {
                        updateObject(address, "attn", value, setAddress);
                      }}
                      label={`Name / ATTN`}
                      valid={false}
                      type="text"
                      bypass={false}
                      description={""}
                    />

                    <TextControl
                      value={address.line1}
                      change={(value) => {
                        updateObject(address, "line1", value, setAddress);
                      }}
                      label={`Address Line 1`}
                      valid={false}
                      type="text"
                      required={true}
                      bypass={false}
                      description={""}
                    />

                    <TextControl
                      value={address.line2}
                      change={(value) => {
                        updateObject(address, "line2", value, setAddress);
                      }}
                      label={`Address Line 2`}
                      valid={false}
                      type="text"
                      bypass={false}
                      description={""}
                    />
                    <div className='flex' style={{ gap: '12px' }}>
                      <TextControl
                        value={address.city}
                        change={(value) => {
                          updateObject(address, "city", value, setAddress);
                        }}
                        label={`City`}
                        valid={false}
                        type="text"
                        required={true}
                        bypass={false}
                        description={""}
                      />

                      <SelectControl
                        value={address.state ? address.state : 'WA'}
                        change={(value) => {
                          updateObject(address, "state", value, setAddress);
                        }}
                        label={'State'}
                        options={states.map((state) => {
                          return {
                            value: state.abbreviation,
                            label: state.abbreviation
                          }
                        })}
                      />

                      <TextControl
                        value={address.postalCode}
                        change={(value) => {
                          updateObject(address, "postalCode", value, setAddress);
                        }}
                        label={`Zip Code`}
                        valid={false}
                        type="text"
                        required={true}
                        bypass={false}
                        description={""}
                      />

                    </div>
                  </div>
                }
                <div className='modal-options flex' style={{ gap: '12px' }}>
                  <button className='secondary' onClick={() => { setStep(0) }}>Back: Proofs</button>
                  {!thisRun.needShipping && <button className='secondary' onClick={() => { setStep(2) }}>Next: Upload Files</button>}
                  {thisRun.needShipping && (shipping == 0 || addressHasChanges) && <button className='primary' disabled={calculatingShipping} onClick={getShipping}>{calculatingShipping ? <span><Loading /> Calculating Shipping...</span> : <span>Calculate Shipping</span>}</button>}
                  {thisRun.needShipping && shipping !== 0 && !addressHasChanges && <button className='secondary' onClick={() => { setStep(2) }}>Next: Upload Files</button>}
                </div>

              </div>
            }
            {step === 2 &&
              <div>
                <p>Upload separate files for your interior and cover. For interiors, be sure to export your project as individual pages rather than spreads.</p>
                <FileControl
                  classlist=''
                  label={'Upload Your Files'}
                  help={'.'}
                  valid={false}
                  reference={editionFiles}
                  multiple={true}
                  disabled={uploading}
                  image={true}
                  accept="image/png, image/jpeg, application/pdf"
                />

                <button className='primary linkish mb-med' onClick={uploadSelected} disabled={uploading}>{uploading ? <span><Loading /> Uploading...</span> : <span>Upload</span>}</button>

                {newEditionFiles.length > 0 &&
                  <div className='mb-med'>
                    <h4>Uploaded Files</h4>
                    {newEditionFiles.map((file, index) =>
                      <div className='mb-small' key={file.file}>{file.name} <a className='linkish smaller ml-med' onClick={() => { removeFile(index) }}>Remove</a></div>
                    )}
                  </div>}

                <div className='modal-options flex' style={{ gap: '12px' }}>
                  <button className='secondary' onClick={() => { setStep(1) }}>Back: Shipping</button>
                  <div>{hasFiles ? 'Yes' : 'no'}</div>
                  {(hasFiles || newEditionFiles.length > 0) && <button className='secondary' onClick={() => { setStep(3) }}>Next: Pay</button>}
                </div>
              </div>
            }
            {step == 3 &&
              <div>
                <div className='small-print mb-small'>By submitting payment, you are agreeing that you have all necessary permissions to print and distribute this material. Chatwin Printing is not liable for any claims of copyright infringement.</div>
                <PaymentForm amount={getTotal()} setCollecting={setCollecting} sendSuccess={sendSuccess} />
                <div className='modal-options flex' style={{ gap: '12px' }}>
                  {!collecting && <button className='secondary' onClick={() => { setStep(2) }}>Back: Files</button>}
                  {collecting && <span>Hold tight, we're charging your card!</span>}
                </div>

              </div>
            }
            {step == 4 &&
              <div>
                <p>Thank you for your payment! We'll take it from here and be in touch with any questions!</p>
                <button className='secondary' onClick={() => close(false)}>Close</button>
              </div>
            }
            {step !== 4 &&
              <div className='mt-med smaller'><a className='cancel' onClick={() => close(false)}>Cancel</a></div>
            }
          </div>
          <div className='half'>
            {thisRun._id && costConfig._id &&
              <div>
                <p>Ordering {thisRun.quantity} copies of <em>{edition.title}</em>.</p>

                <p>Subtotal: ${thisRun.total.toFixed(2)}</p>
                {thisRun.proofType == 'printedShipped' &&
                  <p>Print Proof Shipping: ${costConfig['printProofShipping'].toFixed(2)}</p>
                }
                {thisRun.needShipping && shipping > 0 && !addressHasChanges &&
                  <p>Order shipping (UPS Ground): ${shipping.toFixed(2)}</p>
                }
                <p>WA State Sales Tax: ${tax.toFixed(2)}</p>
                <p><strong>Total: ${getTotal().toFixed(2)}</strong></p>

              </div>
            }
          </div>




        </div>

      </div>
    </div >
  )
}


