import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import SelectControl from '../../components/SelectControl';
import moment from 'moment';
import { updateObject, updateSubObject } from '../../Utils.js';

export default function AccountUserEdit({ sessionValid, roles }) {
  const [user, setUser] = useState({});
  const [costConfig, setCostConfig] = useState({});

  const defaultAddress = {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
  };

  document.title = 'Editing User | Chatwin Printing';

  const { id } = useParams();

  const getUser = async () => {
    const data = await window.CPA.makeRequest(`user?id=${id}`, 'GET');
    if (data) {
      data.address = data.address ? data.address : defaultAddress;
      setUser(data);
    }
  }

  const getConfig = async () => {
    const data = await window.CPA.makeRequest("get-cost-config", "GET");
    if (data && data._id) {
      setCostConfig(data);
    }
  };

  const saveUser = async () => {
    const data = await window.CPA.makeRequest(`user`, 'POST', user);
    window.CPA.message({ type: 'success', temp: true, text: 'Customer profile updated', label: 'Success' });

    getUser();
  }


  useEffect(() => {
    getUser();
    getConfig();
  }, []);

  if (!user._id) {
    return (<div></div>)
  }

  if (!sessionValid && !roles.admin) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  } else if (!roles.admin) {
    return (<div className='card-container'>You do not have permission to view this page.</div>)
  }

  return (
    <div className='card-container'>
      <div className='page-header'>
        <div>
          <h1>{user.email}</h1>
          <div>{user.name} / {user.organization}</div>
        </div>
      </div>

      <div className='flex sb'><h3>User Details</h3></div>

      <TextControl
        value={user.name}
        change={(value) => {
          updateObject(user, 'name', value, setUser);
        }}
        label={`Customer Name`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.organization}
        change={(value) => {
          updateObject(user, 'organization', value, setUser);
        }}
        label={`Customer Organization`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.phone}
        change={(value) => {
          updateObject(user, 'phone', value, setUser);
        }}
        label={`Customer Phone`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      {costConfig.markupLevels &&
        <SelectControl
          value={user.markupLevel}
          change={(value) => {
            updateObject(user, 'markupLevel', value, setUser);
          }}
          label={"Markup Level"}
          help={""}
          options={Object.keys(costConfig.markupLevels).map((key) => {
            return {
              label: `${key} (${costConfig.markupLevels[key] * 100}%)`,
              value: key
            }
          })}
        />
      }

      <div className='flex sb'><h3>Default Address</h3></div>

      <TextControl
        value={user.address.line1}
        change={(value) => {
          updateSubObject(user, 'address', 'line1', value, setUser);
        }}
        label={`Address Line 1`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.address.linew}
        change={(value) => {
          updateSubObject(user, 'address', 'line2', value, setUser);
        }}
        label={`Address Line 2`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.address.city}
        change={(value) => {
          updateSubObject(user, 'address', 'city', value, setUser);
        }}
        label={`City`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.address.state}
        change={(value) => {
          updateSubObject(user, 'address', 'state', value, setUser);
        }}
        label={`State`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.address.postalCode}
        change={(value) => {
          updateSubObject(user, 'address', 'postalCode', value, setUser);
        }}
        label={`Postal Code`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />



      <button className='primary linkish' onClick={saveUser}>Save Changes</button>

    </div>
  )
}
