import { ReactComponent as LoadingIcon } from './loading.svg';

const slugify = (str) => {
  return str
    .normalize("NFD").replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

export const uploadFile = async (file, title) => {
  const token = localStorage.getItem('cpaToken');
  let originalName = file.name;
  let originalParts = originalName.split('.');
  let extension = originalParts[originalParts.length - 1];
  const formData = new FormData();
  formData.append('file', file);
  let response = await fetch(`${window.CPA.api}file-upload?&ext=${extension}&name=${originalName}&token=${token}&slug=${!title ? 'untitled' : slugify(title)}`, {
    method: 'post',
    body: formData,
  });
  let data = await response.json()
  return data;
}

const getHeads = (method, body = null) => {
  let heads = {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  if (body) {
    heads.body = JSON.stringify(body);
  }
  return heads;
}

export const getStatusClass = (status) => {
  let key = {
    'Quote': 'quote',
    'Ordered': 'ordered',
    'Proofs': 'proofs',
    'Approved': 'approved',
    'In Production': 'production',
    'Shipped': 'shipped',
    'Ready': 'shipped'
  }
  return key[status];
}

export const makeRequest = async (url, method, body) => {
  url = window.CPA.api + url;
  const token = localStorage.getItem('cpaToken');
  url += url.includes('?') ? `&token=${token}` : `?token=${token}`;
  let heads = getHeads(method, body ? body : null);
  let response = await fetch(url, heads);
  let data = await response.json();
  return (data);
}

export const isProjectOpen = (project) => {
  const now = new Date().getTime();
  if (project.type == 'Range' && now > new Date(project.start).getTime() && now < new Date(project.end).getTime()) {
    return true;
  } else if (project.type == 'Start' && now > new Date(project.start).getTime()) {
    return true;
  } else if (project.type == 'End' && now < new Date(project.end).getTime()) {
    return true;
  } else if (project.type == 'Rolling') {
    return true;
  }
}

export const updateObject = (obj, field, value, change) => {
  let newObject = { ...obj };
  newObject[field] = value;
  change(newObject);
}

export const updateSubObject = (obj, subObj, field, value, change) => {
  let newObject = { ...obj };
  if (!newObject[subObj]) {
    newObject[subObj] = {};
  }
  newObject[subObj][field] = value;
  change(newObject);
}

// Dates!

export const ISODateNow = () => {
  let date = new Date();
  let full = date.toISOString();
  let parts = full.split('T');
  return parts[0];
}

export const formatISODate = (date) => {
  let full = date.toISOString();
  let parts = full.split('T');
  return parts[0];
}

export const returnISODate = (date) => {
  let parts = date.split('T');
  return parts[0];
}

export const getDateOrderedMaybe = (project) => {
  if (project.dateOrdered) {
    return project.dateOrdered;
  } else if (project.events) {
    let ordered = '';
    project.events.forEach((event) => {
      if (event.description.includes('status to Ordered')) {
        ordered = event.date;
      }
    });
    return ordered ? formatISODate(new Date(ordered)) : ''
  } else {
    return '';
  }
}

// Quotes! 

export const getQuote = async (run, edition, customer, reviewOveride = false) => {
  const costConfig = await getCostConfig();
  run.quoteBreakdown = [];
  run.total = 0;
  run.subtotal = 0;
  run.reviewMessage = '';
  run.needsReview = false;

  if (edition.customerRequests && edition.customerRequests.length > 0) {
    run.needsReview = true;
    run.reviewMessage += `Customer may have special requests, including: ${edition.customerRequests.join(', ')}.`;
  }

  if (edition.customerNotes && edition.customerNotes.length > 0) {
    run.needsReview = true;
    run.reviewMessage += ' Customer left notes.';
  }

  const trim = edition.trim == 'custom' ? edition.trimCustom : edition.trim;

  if (edition.type == 'Paperback') {

    if (edition.binding == 'Perfect' && edition.pages < 30) {
      run.needsReview = true;
      run.reviewMessage += ` User chose perfect binding for a ${edition.pages}-page book.`;
    } else if (edition.binding == 'Perfect' && edition.pages > 600) {
      run.needsReview = true;
      run.reviewMessage += ` Perfect-bound project is over 600 pages.`;
    } else if (edition.binding == 'Staple' && edition.pages > 30) {
      run.needsReview = true;
      run.reviewMessage += ` Staplebound project is over 30 pages.`;
    } else if (edition.pages < 4) {
      run.needsReview = true;
      run.reviewMessage += ` Very few pages.`;
    }


    let interiorPaperSheet = await getPaperData(edition.interiorPaper);
    let coverPaperSheet = await getPaperData(edition.coverPaper);

    if (!interiorPaperSheet.available) {
      run.needsReview = true;
      run.reviewMessage += 'Interior paper is out of stock.'
    }

    if (!coverPaperSheet.available) {
      run.needsReview = true;
      run.reviewMessage += 'Cover paper is out of stock.'
    }

    let up = getUp(trim, edition.fullBleed, 2);
    let coverUp = await getCoverUp(edition, interiorPaperSheet, coverPaperSheet);

    let sheets = Math.ceil(edition.pages / up);

    // If this is an odd size, let's just assume 1 sheet per cover but flag it for review.
    if (coverUp <= 0 || isNaN(coverUp)) {
      coverUp = 1;
      run.needsReview = true;
      run.reviewMessage += ' Project too large for standard cover paper.';
    }

    // If this is an odd size, let's just assume 1 sheet per cover but flag it for review.
    if (up <= 0 || isNaN(up)) {
      up = 1;
      run.needsReview = true;
      run.reviewMessage += ' Project too large for standard interior paper.';
    }

    // How much does the interior paper cost us?
    let interiorPaper = interiorPaperSheet.costPerSheet * sheets * run.quantity;
    const dimensions = trim.split("x");
    let short = parseFloat(dimensions[0]);
    let long = parseFloat(dimensions[1]);
    if (short > 5.5 && short <= 6 && long > 8.5 && long <= 9) {
      up = 8;
      interiorPaper = interiorPaper * 1.18;
    }
    run.total += interiorPaper;
    run.quoteBreakdown.push({
      code: 'interiorPaper',
      description: `${sheets * run.quantity} sheets of ${interiorPaperSheet.weight} ${interiorPaperSheet.name} (printing ${up / 2}up)`,
      total: interiorPaperSheet.costPerSheet * sheets * run.quantity
    })

    // How much does the cover paper cost us?
    run.total += coverPaperSheet.costPerSheet * run.quantity / coverUp;
    run.quoteBreakdown.push({
      code: 'coverPaper',
      description: `${run.quantity / coverUp} sheets of ${coverPaperSheet.weight} ${coverPaperSheet.name} (printing ${coverUp}up)`,
      total: coverPaperSheet.costPerSheet * run.quantity / coverUp
    });

    // How much is lamination?
    if (edition.lamination !== 'None' && edition.lamination !== 'No Lamination') {
      run.total += run.quantity * costConfig.laminationOptions[edition.lamination] / coverUp;
      run.quoteBreakdown.push({
        code: 'lamination',
        description: `${edition.lamination} lamination`,
        total: run.quantity * costConfig.laminationOptions[edition.lamination] / coverUp
      })
    }

    // How much is the ink for the interior?
    run.total += costConfig.inkPerPage[edition.interiorPrinting] * sheets * 2 * run.quantity;
    run.quoteBreakdown.push({
      code: 'interiorInk',
      description: `Ink cost for interior`,
      total: costConfig.inkPerPage[edition.interiorPrinting] * sheets * 2 * run.quantity
    });

    // How much is the ink for the cover?
    let coverInk = costConfig.inkPerPage["fourColor"] * run.quantity * (edition.coverTwoSided ? 2 : 1); // Cover ink
    coverInk = coverInk / coverUp;

    run.total += coverInk;
    run.quoteBreakdown.push({
      code: 'coverInk',
      description: `Ink cost for cover ${edition.coverTwoSided ? 'printed double-sided' : ''}`,
      total: coverInk
    });

    // How much is binding?
    let bindingTier = run.quantity > 500 ? 1000 : Math.ceil(run.quantity / 100) * 100;
    bindingTier = `${bindingTier}`;
    const bindingConfig = edition.binding === 'Perfect' ? costConfig.perfectBindingCost : costConfig.stapleBindingCost
    run.total += bindingConfig[bindingTier] * run.quantity;
    run.quoteBreakdown.push({
      code: 'binding',
      description: `${edition.binding} binding`,
      total: bindingConfig[bindingTier] * run.quantity
    });

    // Any extra costs that need markup?
    if (edition.additionalCosts) {
      edition.additionalCosts.forEach((cost) => {
        if (cost.markedUp) {
          let costTotal = cost.unit ? cost.amount * run.quantity : cost.amount;
          run.total += costTotal;
          run.quoteBreakdown.push({
            code: 'custom',
            description: `Custom: ${cost.description}`,
            total: costTotal
          });
        }
      })
    }

    if (run.additionalCosts) {
      run.additionalCosts.forEach((cost) => {
        if (cost.markedUp) {
          let costTotal = cost.unit ? cost.amount * run.quantity : cost.amount;
          run.total += costTotal;
          run.quoteBreakdown.push({
            code: 'custom',
            description: `Custom: ${cost.description}`,
            total: costTotal
          });
        }
      })
    }

    // Now we have to charge overage
    run.total = run.total * (1 + costConfig.overagePercentage);
    run.quoteBreakdown.push({
      code: 'overage',
      description: `${costConfig.overagePercentage * 100}% overage`,
      total: run.total * costConfig.overagePercentage,
    });
    // Or we can do flats!
  } else if (edition.type == 'Flat') {

    let flatPaperSheet = await getPaperData(edition.flatPaper);
    let up = getUp(trim, true, 1);

    if (run.flatCollated) {
      run.needsReview = true;
      run.reviewMessage += ' User needs collation, check that out.';
    }

    if (!flatPaperSheet.available) {
      run.needsReview = true;
      run.reviewMessage += ' Flat paper is out of stock.'
    }

    // If this is an odd size, let's just assume 1 sheet per page but flag it for review.
    if (up <= 0 || isNaN(up)) {
      up = 1;
      run.needsReview = true;
      run.reviewMessage += ' Project too large for standard paper.';
    }

    let sheets = Math.ceil(edition.pages * run.quantity / up);

    // How much does the paper cost us?
    run.total += flatPaperSheet.costPerSheet * sheets;
    run.quoteBreakdown.push({
      code: 'flatPaper',
      description: `${sheets} sheets of ${flatPaperSheet.weight} ${flatPaperSheet.name} (printing ${up}up)`,
      total: flatPaperSheet.costPerSheet * sheets
    })

    // How much is the ink?
    const inkPages = edition.flatTwoSided ? 2 : 1;
    run.total += costConfig.inkPerPage[edition.flatPrinting] * sheets * inkPages;
    run.quoteBreakdown.push({
      code: 'flatInk',
      description: `Ink cost`,
      total: costConfig.inkPerPage[edition.flatPrinting] * sheets * inkPages
    });

    // How much is lamination?
    run.total += sheets * costConfig.laminationOptions[edition.lamination];
    run.quoteBreakdown.push({
      code: 'lamination',
      description: `${edition.lamination} lamination`,
      total: sheets * costConfig.laminationOptions[edition.lamination]
    })

    // Any extra costs that need markup?
    if (edition.additionalCosts) {
      edition.additionalCosts.forEach((cost) => {
        if (cost.markedUp) {
          let costTotal = cost.unit ? cost.amount * run.quantity : cost.amount;
          run.total += costTotal;
          run.quoteBreakdown.push({
            code: 'custom',
            description: `Custom: ${cost.description}`,
            total: costTotal
          });
        }
      })
    }

    if (run.additionalCosts) {
      run.additionalCosts.forEach((cost) => {
        if (cost.markedUp) {
          let costTotal = cost.unit ? cost.amount * run.quantity : cost.amount;
          run.total += costTotal;
          run.quoteBreakdown.push({
            code: 'custom',
            description: `Custom: ${cost.description}`,
            total: costTotal
          });
        }
      })
    }
    // End additional costs

  }


  // Now let's add the markup
  let markup = costConfig.markupLevels[customer.markupLevel];

  run.quoteBreakdown.push({
    code: 'markup',
    description: `${costConfig.markupLevels[customer.markupLevel] * 100}% markup`,
    total: run.total * costConfig.markupLevels[customer.markupLevel]
  });

  run.total = run.total * (1 + markup);

  // Add the setup fee if this is a first-time order
  if (run.firstRun) {
    run.total += costConfig.setupFee;
    run.quoteBreakdown.push({
      code: 'setUp',
      description: `Standard setup fee`,
      total: costConfig.setupFee,
    });
  }

  // Any extra costs that don't need markup?
  if (edition.additionalCosts) {
    edition.additionalCosts.forEach((cost) => {
      if (!cost.markedUp) {
        let costTotal = cost.unit ? cost.amount * run.quantity : cost.amount;
        run.total += costTotal;
        run.quoteBreakdown.push({
          code: 'custom',
          description: `Custom: ${cost.description}`,
          total: costTotal
        });
      }
    })
  }

  if (run.additionalCosts) {
    run.additionalCosts.forEach((cost) => {
      if (!cost.markedUp) {
        let costTotal = cost.unit ? cost.amount * run.quantity : cost.amount;
        run.total += costTotal;
        run.quoteBreakdown.push({
          code: 'custom',
          description: `${cost.description}`,
          total: costTotal
        });
      }
    })
  }

  run.subtotal = run.total;

  if (!run.shipping) {
    run.shipping = 0;
  }
  if (!run.proofCost) {
    run.proofCost = 0;
  }
  if (!run.proofShipping) {
    run.proofShipping = 0;
  }

  if (reviewOveride) {
    run.needsReview = false;
  }

  run.total += run.shipping;
  run.total += run.proofShipping;
  run.total += run.proofCost;

  if (run.quantity > costConfig.maxRuns[edition.type]) {
    run.needsReview = true;
    run.reviewMessage += ` Customer requested a print run over ${costConfig.maxRuns[edition.type]}.`;
  }

  const today = new Date();
  run.dateQuoted = today.toString();
  run.quoteExpires = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3).toString();
  console.log(run);
  return run;

}

//// Shipping
export const getShippingTotal = async (run, edition, postalCode) => {

  let total = 0;

  if (edition.type == 'Paperback') {
    let interiorPaperSheet = await getPaperData(edition.interiorPaper);
    let coverPaperSheet = await getPaperData(edition.coverPaper);

    let tall = 12;

    let width = 0;
    let height = 0;
    const trim = edition.trim == 'custom' ? edition.trimCustom : edition.trim;
    const dimensions = trim.split("x");
    width = parseFloat(dimensions[0]);
    height = parseFloat(dimensions[1]);
    let short = width < height ? width : height;
    let long = width < height ? height : width;

    let spine = edition.pages / 2 * parseFloat(interiorPaperSheet.caliper);
    spine += parseFloat(coverPaperSheet.caliper) * 2;

    let numberPer = tall / spine;
    if (short < 7) {
      numberPer = numberPer * 2;
    }

    let weightPerSheet = parseInt(interiorPaperSheet.weight) * 16 / 500; // Weight in ounces
    let parentSquare = 26 * 40;
    let inch = weightPerSheet / parentSquare;

    let coverWeightPerSheet = parseInt(coverPaperSheet.weight) * 16 / 500; // Weight in ounces
    let coverParentSquare = 20 * 26;
    let coverInch = coverWeightPerSheet / coverParentSquare;

    let weightPer = inch * short * long * edition.pages / 2;
    weightPer += coverInch * height * (width + spine);


    let packages = Math.ceil(run.quantity / numberPer);
    let totalWeight = weightPer * run.quantity;

    let rate = await getPackageTotal(totalWeight / packages, postalCode, 12, 12, 12);

    total += (rate * packages);

  } else if (edition.type == 'Flat') {
    let flatPaperSheet = await getPaperData(edition.flatPaper);
    const trim = edition.trim == 'custom' ? edition.trimCustom : edition.trim;
    const dimensions = trim.split("x");
    let width = parseFloat(dimensions[0]);
    let height = parseFloat(dimensions[1]);

    let up = getUp(trim, true, 1);

    if (up == 0 || isNaN(up)) {
      up = 1;
    }

    let sheets = edition.pages * run.quantity / up;
    let totalHeight = sheets * parseFloat(flatPaperSheet.caliper);

    let tall = 6;

    let packages = Math.ceil(totalHeight / tall);

    let weightPerSheet = parseInt(flatPaperSheet.weight) * 16 / 500; // Weight in ounces
    let parentSquare = 26 * 40;
    let inch = weightPerSheet / parentSquare;

    let totalWeight = inch * width * height * edition.pages * run.quantity;


    let rate = await getPackageTotal(totalWeight / packages, postalCode, width, height, tall);

    total += (rate * packages);

  }

  return total * 1.2;
}

const getPackageTotal = async (weight, postalCode, length, width, height) => {
  let shippingObject = {
    object: 'Shipment',
    to_address: {
      country: 'US',
      zip: postalCode,
    },
    from_address: {
      name: 'Sublunary Editions',
      street1: '2400 NW 80th Street #206',
      street2: '',
      city: 'Seattle',
      state: 'Washington',
      country: 'US',
      zip: '98117'
    },
    parcel: {
      object: "Parcel",
      length: length,
      width: width,
      height: height,
      weight: weight,
    }
  }
  const data = await window.CPA.makeRequest('shipping', 'POST', shippingObject);
  if (data.rates) {
    for (let i = 0; i < data.rates.length; i++) {
      if (data.rates[i].carrier == 'UPSDAP' && data.rates[i].service == 'Ground') {
        return parseFloat(data.rates[i].rate);
      }
    }
  } else {
    return false;
  }
}

const getCostConfig = async () => {
  const data = await window.CPA.makeRequest("get-cost-config", "GET");
  return data;
}

const getPaperData = async (id) => {
  if (!id) {
    return false;
  }
  let data = await window.CPA.makeRequest(`get-paper?id=${id}`, 'GET');
  return data;
}

export const getSpine = async (edition) => {
  let interiorPaperSheet = await getPaperData(edition.interiorPaper);
  let coverPaperSheet = await getPaperData(edition.coverPaper);
  let spine = edition.pages / 2 * parseFloat(interiorPaperSheet.caliper);
  spine += parseFloat(coverPaperSheet.caliper) * 2;
  return spine;
}

const getCoverUp = async (edition, interiorPaperSheet, coverPaperSheet) => {
  const paperShort = 12.5;
  const paperLong = 18.5;

  let width = 0;
  let height = 0;
  const trim = edition.trim == 'custom' ? edition.trimCustom : edition.trim;

  const dimensions = trim.split("x");
  width = parseFloat(dimensions[0]);
  height = parseFloat(dimensions[1]);

  let spine = edition.pages / 2 * parseFloat(interiorPaperSheet.caliper);
  spine += parseFloat(coverPaperSheet.caliper) * 2;

  width = (width * 2) + spine;

  let short = width;
  let long = height;

  let maxWide = Math.floor(paperShort / short);
  let wideGutter = (maxWide - 1) * 0.25;
  if (short * maxWide + wideGutter > paperShort) {
    maxWide--;
  }
  let maxHigh = Math.floor(paperLong / long);
  let highGutter = (maxHigh - 1) * 0.25;
  if (long * maxHigh + highGutter > paperLong) {
    maxHigh--;
  }
  return maxWide * maxHigh;
}

const getUp = (trim, fullBleed = false, sides = 2) => {
  const paperShort = 12.5;
  const paperLong = 18.5;
  const dimensions = trim.split("x");
  let short = parseFloat(dimensions[0]);
  let long = parseFloat(dimensions[1]);

  if (fullBleed) {
    short += .25;
    long += .25;
  }

  let maxWide = Math.floor(paperShort / short);
  let wideGutter = (maxWide - 1) * 0.25;
  if (short * maxWide + wideGutter > paperShort) {
    maxWide--;
  }
  let maxHigh = Math.floor(paperLong / long);
  let highGutter = (maxHigh - 1) * 0.5;
  if (long * maxHigh + highGutter > paperLong) {
    maxHigh--;
  }
  return maxWide * maxHigh * sides;
};

export const getPapers = async () => {
  const data = await window.CPA.makeRequest("get-quote-papers", "GET");
  let papers = {};
  data.forEach((paper) => {
    paper.type.forEach((type) => {
      if (papers[type]) {
        papers[type].push(paper);
      } else {
        papers[type] = [];
        papers[type].push(paper);
      }
    })
  })
  return papers;
}

export const sendUserInvite = async (email, callback = null) => {
  let request = await window.CPA.makeRequest(`send-invite?email=${email}`, 'GET');
  window.CPA.message({ type: 'success', label: 'success', text: 'Invite sent!', temp: true });
  if (callback) {
    callback();
  }
  return true;
}

export function Loading() {
  return (<><LoadingIcon /></>)
}

export const sendEmail = async (to, message, subject) => {

  const data = {
    to: to,
    message: message,
    subject: subject
  }
  const request = await window.CPA.makeRequest('send-email', 'POST', data);
}

const getFileUrl = async (file) => {
  let data = await window.CPA.makeRequest(`file-url?file=${file}`, 'GET');
  return data.url;
}

export const downloadFile = async (file) => {
  let url = await getFileUrl(file);
  const link = document.createElement('a');
  link.setAttribute('download', '');
  link.setAttribute('target', '_blank');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
}