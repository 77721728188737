import React, { useEffect, useState, Fragment } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate
} from 'react-router-dom';
import './App.css';

import Header from './components/Header';
import Message from './components/Message';

// Pages
import Home from './pages/Home';

// Auth
import AuthLogin from './pages/AuthLogin';
import AuthCreateAccount from './pages/AdminUserCreate';
import AuthFirstTimeUser from './pages/AuthFirstTimeUser';

// Admin
import AdminOptions from './pages/AdminOptions';
import AdminOtherOptions from './pages/AdminOtherOptions';
import AdminPapers from './pages/AdminPapers';
import AdminNewProject from './pages/AdminNewProject';
import AdminProjects from './pages/AdminProjects';
import AdminKanban from './pages/AdminKanban';
import AdminProjectEdit from './pages/AdminProjectEdit';
import AdminUsers from './pages/AdminUsers';
import AdminUserEdit from './pages/AdminUserEdit';
import AdminUserCreate from './pages/AdminUserCreate';
import AdminSpineWidget from './pages/AdminSpineWidget';

// Account
import AccountProjects from './pages/AccountProjects';
import AccountProjectEdit from './pages/AccountProjectEdit';
import AccountNewQuote from './pages/AccountNewQuote';
import AccountUserEdit from './pages/AccountUserEdit';

// Front-End!
import QuoteSubmit from './pages/QuoteSubmit';
import Confirmation from './pages/Confirmation';

import { makeRequest, uploadFile } from './Utils.js';

//const api = 'http://localhost:3009/';
const api = 'https://chatwinprinting.com/api/';

const messageEvent = (config) => {
  const event = new CustomEvent('cpaMessage', { detail: config });
  document.dispatchEvent(event);
}

window.CPA = {
  api: api,
  makeRequest: makeRequest,
  uploadFile: uploadFile,
  message: messageEvent,
};

function App() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [sessionValid, setSessionValid] = useState(false);
  const [banner, setBanner] = useState({});
  const [roles, setRoles] = useState({});

  /**
  * Let's check that auth status!
  *
  */
  const checkAuth = async (id = null) => {
    const token = localStorage.getItem('cpaToken');
    if (!token) {
      setSessionValid(sessionValid => false);
      return false;
    } else {
      try {
        let data = await makeRequest(`validate?token=${token}`, 'GET')
        if (!data.valid) {
          setSessionValid(sessionValid => false);
          return false;
          localStorage.setItem('cpaToken', '');
        } else {
          setSessionValid(sessionValid => true);
          setEmail(data.email);
          setName(data.name);
          setRoles(data.roles);
          window.CPA.user = data;
          return true;
        }
      } catch (err) {

      }
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  const showMessage = (config) => {
    if (!config) {
      return;
    }
    setBanner(banner => config);
    if (config.temp) {
      setTimeout(() => {
        let blank = {};
        setBanner(blank);
      }, 3500)
    }
  }

  document.addEventListener('cpaMessage', (e) => {
    showMessage(e.detail);
  });

  return (
    <BrowserRouter>
      <div className='app-container'>
        <Header sessionValid={sessionValid} email={email} name={name} checkAuth={checkAuth} roles={roles} />
        <div className='page-container'>
          <div className='content-container'>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* Auth Routes */}
              <Route path="/login" element={<AuthLogin sessionValid={sessionValid} checkAuth={checkAuth} />} />
              <Route path="/first-time-user" element={<AuthFirstTimeUser sessionValid={sessionValid} />} />
              {/* Admin Routes */}
              <Route path="/admin/pricing-options" element={<AdminOptions sessionValid={sessionValid} roles={roles} />} />
              <Route path="/admin/other-options" element={<AdminOtherOptions sessionValid={sessionValid} roles={roles} />} />
              <Route path="/admin/papers" element={<AdminPapers sessionValid={sessionValid} roles={roles} />} />
              <Route path="/admin/quote-calculator" element={<AdminNewProject sessionValid={sessionValid} roles={roles} />} />
              <Route path="/admin/all-projects" element={<AdminProjects sessionValid={sessionValid} roles={roles} />} />
              <Route path="/admin/project-kanban" element={<AdminKanban sessionValid={sessionValid} roles={roles} />} />
              <Route path="/admin/all-customers" element={<AdminUsers sessionValid={sessionValid} roles={roles} />} />
              <Route path="/admin/create-customer" element={<AdminUserCreate sessionValid={sessionValid} roles={roles} />} />
              <Route path="/admin/customer/:id" element={<AdminUserEdit sessionValid={sessionValid} roles={roles} />} />
              <Route path="/admin/project/:id" element={<AdminProjectEdit sessionValid={sessionValid} roles={roles} />} />
              <Route path="/admin/spine-calculator" element={<AdminSpineWidget sessionValid={sessionValid} roles={roles} />} />

              {/* Account Routes */}
              <Route path="/account/my-projects" element={<AccountProjects sessionValid={sessionValid} roles={roles} />} />
              <Route path="/account/project/:id" element={<AccountProjectEdit sessionValid={sessionValid} roles={roles} />} />
              <Route path="/account/new-project" element={<AccountNewQuote sessionValid={sessionValid} roles={roles} />} />
              <Route path="/account" element={<AccountUserEdit sessionValid={sessionValid} roles={roles} />} />
              {/* Front-End */}
              <Route path="/get-a-printing-quote" element={<QuoteSubmit sessionValid={sessionValid} roles={roles} />} />
              <Route path="/quote-confirmation" element={<Confirmation />} />
            </Routes>
          </div>
        </div>
        {banner.text && <Message config={banner} showMessage={showMessage} />}
      </div>
    </BrowserRouter>
  );
}

export default App;
