import React, {useEffect, useState} from 'react';
import { ReactComponent as Close } from '../../icons/close.svg';
import './style.css';

export default function Message({config, showMessage}) {
  const [classList, setClassList] = useState('');

  useEffect(() => {
    if (config.temp) {
      setTimeout(() => {
        setClassList('invisible');
      }, 3000)
    }
  }, []);

  return (
    <div className={`display-message ${config.type} ${classList}`}>
      <div>
        <div className='message-label'>{config.label ? config.label : config.type}</div>
        <div className='message-text' dangerouslySetInnerHTML={{__html: config.text}}></div>
      </div>
      {!config.temp && <a className='message-clear' onClick={() => {showMessage({})}}><Close /></a>}
    </div>
  )
}
