import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextControl from '../../components/TextControl';

export default function Login({ sessionValid, checkAuth }) {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');

  document.title = 'Chatwin Printing | Login';
  document.querySelector('meta[name="description"]').setAttribute('content', 'Login to your account to access your projects.');


  const navigate = useNavigate();

  const submit = async () => {
    let user = {
      email: email,
      pass: pass
    }
    let data = await window.CPA.makeRequest('login', 'POST', user);
    if (data.token) {
      window.CPA.message({ type: 'success', label: 'success', text: 'User logged in successfully!', temp: true });
      localStorage.setItem('cpaToken', data.token);
      const queryParams = new URLSearchParams(window.location.search);
      const ret = queryParams.get('return');
      setTimeout(() => {
        checkAuth();
        navigate(ret ? ret : '/account')
      }, 300);
    } else {
      window.CPA.message({ type: 'error', temp: true, text: data.message, label: 'Error' })
    }
  }

  if (sessionValid) {
    return (
      <div>
        <h4>Whoa there. You're already logged in. Go to <Link to={'/account'}>your account</Link></h4>
      </div>
    )
  }

  return (
    <div className='card-container'>
      <div className='page-header'>
        <h1>Login to your account</h1>
      </div>
      <TextControl
        value={email}
        change={(value) => { setEmail(value) }}
        label={`Account Email`}
        valid={false}
        type='text'
        required={true}
        bypass={true}
        description={''}
      />
      <TextControl
        value={pass}
        change={(value) => { setPass(value) }}
        label={`Password`}
        valid={false}
        type='password'
        bypass={true}
        required={true}
        description={''}
      />

      <button className='primary linkish' onClick={submit}>Login</button>
    </div>
  )
}
