import React, { useState, useEffect } from "react";
import { Link, useInRouterContext, useNavigate } from "react-router-dom";
import TextControl from "../../components/TextControl";
import SelectControl from "../../components/SelectControl";
import BinaryControl from "../../components/BinaryControl";
import NumberControl from "../../components/NumberControl";
import MultiSelect from "../../components/MultiSelect";
import TextAreaControl from "../../components/TextAreaControl";
import { updateObject, updateSubObject, getQuote, getSpine } from "../../Utils";
import QuoteGenerator from '../../components/QuoteGenerator';

const defaultEdition = {
  title: "",
  author: "",
  type: "Paperback",
  binding: "Perfect",
  interiorPaper: "",
  flatPaper: "",
  flatPrinting: "blackAndWhite",
  flatTwoSide: false,
  needShipping: true,
  needsReview: false,
  pages: 200,
  coverPaper: "",
  fullBleed: false,
  interiorPrinting: "blackAndWhite",
  lamination: "Matte Soft-Touch",
  coverTwoSided: false,
  trim: "",
  trimCustom: "5x7",
  customerNotes: '',
  customerRequests: '',
  created: new Date().toString()
};

const defaultRun = {
  quantity: 100,
  firstRun: true,
  created: new Date().toString(),
  events: []
}

// Quote / Ordered / In Production / Shipped

export default function NewProject({ sessionValid, roles }) {
  const [costConfig, setCostConfig] = useState({});
  const [edition, setEdition] = useState(defaultEdition);
  const [run, setRun] = useState(defaultRun)
  const [textPapers, setTextPapers] = useState([]);
  const [coverPapers, setCoverPapers] = useState([]);
  const [allPapers, setAllPapers] = useState([]);
  const [gettingQuote, setGettingQuote] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [customer, setCustomer] = useState({});
  const [saving, setSaving] = useState(false);

  document.title = 'Get a New Printing Quote | Chatwin Printing'


  const navigate = useNavigate();

  useEffect(() => {
    setHasChanges(true);
  }, [run, edition]);

  const getCustomer = async () => {
    let customerData = await window.CPA.makeRequest("get-current-user", "GET");
    setCustomer(customerData);
  }

  const saveTheQuote = async () => {
    if (!edition.title || edition.title == '') {
      window.CPA.message({ type: 'error', temp: true, text: 'Project needs a title', label: 'Error' });
      return;
    }
    setSaving(true);
    let newEdition = { ...edition };
    newEdition.spine = await getSpine(newEdition);

    if (run.needsReview) {
      run.status = 'Quote';
      newEdition.needsReview = true;
      newEdition.reviewMessage = run.reviewMessage;
      delete run.reviewMessage;
    }

    run.events.push({
      type: 'statusChange',
      description: `Project created by ${window.CPA.user.name}`,
      user: window.CPA.user.id,
      date: new Date().toString()
    })

    let body = {
      run: run,
      edition: newEdition,
      customer: customer,
    }

    let data = await window.CPA.makeRequest('create-new-quote', 'POST', body);
    if (data.ok) {
      window.CPA.message({ type: 'success', label: 'success', text: 'Your quote is all good!', temp: true });
      setSaving(false);
      navigate(`/account/project/${data.edition}`);
    }

  }

  useEffect(() => {
    getCustomer();
  }, []);

  if (!sessionValid) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  }

  return (
    <div className='card-container'>
      <div className="page-header">
        <h1>Get a Quote for a New Project</h1>
      </div>
      <p>Tell us about your project and get an instant quote. Quotes are good for 72 hours.</p>

      <TextControl
        value={edition.title}
        change={(value) => {
          updateObject(edition, "title", value, setEdition);
        }}
        label={`Project Title`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={edition.author}
        change={(value) => {
          updateObject(edition, "author", value, setEdition);
        }}
        label={`Project Author`}
        valid={false}
        type="text"
        required={false}
        bypass={false}
        description={""}
      />

      <QuoteGenerator edition={edition} run={run} hasChanges={hasChanges} setHasChanges={setHasChanges} setEdition={setEdition} customer={customer} setRun={setRun} />

      {!hasChanges && run.total && run.total > 0 &&
        <div className='mb-med'>
          <div className='estimated-price'><strong>Estimated Price:</strong> ${run.total.toFixed(2)} <span className='unit'>(${(run.total / run.quantity).toFixed(2)} / unit)</span></div>
          {run.needsReview && <p>Note: You've selected options that may affect the above price. Click save and we'll review your project and provide an updated quote within two business days!</p>}
          <div className='small-print mt-small'>Price does not include shipping (if needed) or sales tax.</div>

          {!run.neewsReview && <p>Save this quote and place an order.</p>}
          <button className='primary' onClick={saveTheQuote}>Save & Order</button>
        </div>
      }
    </div>
  );
}
