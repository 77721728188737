import React from 'react';


export default function Confirmation() {

  document.title = 'Contact Success! | Chatwin Printing';
  document.querySelector('meta[name="description"]').setAttribute('content', 'Message successfully submitted.');


  return (
    <div className='card-container'>
      <div className='page-header'>
        <h1>Success!</h1>
      </div>
      <p>We received your request and we're working on it! We'll be in touch within two business days about your account.</p>
    </div>
  )
}
