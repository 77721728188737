import React, { useEffect, useState } from 'react';

export default function SelectControl({ value, change, label = '', name = '', help = '', valid = true, options = [], classlist = '', disabled = false }) {
  return (
    <div className={`text-control ${classlist}`}>
      {label && <label for={name} className='form-label'>{label}</label>}
      <select id={name} disabled={disabled ? 'disabled' : ''} className={`${valid && 'valid'}`} value={value} onChange={(e) => { change(e.target.value) }}>
        {options.length > 0 && options.map((opt) =>
          <option value={opt.value}>{opt.label}</option>
        )}
      </select>
      {help && <div className='form-help'>{help}</div>}
    </div>
  )
}
