import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import moment from 'moment';
import { sendUserInvite } from '../../Utils';

export default function AdminUsers({ sessionValid, roles }) {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [per, setPer] = useState(20);

  const getUsers = async () => {
    const data = await window.CPA.makeRequest('list-all-customers', 'GET');
    if (data) {
      setUsers(Object.values(data));
    }
  }

  document.title = 'Customer List | Chatwin Printing';


  useEffect(() => {
    getUsers();
  }, []);

  if (!sessionValid && !roles.admin) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  } else if (!roles.admin) {
    return (<div className='card-container'>You do not have permission to view this page.</div>)
  }

  return (
    <div className='card-container'>
      <div className='page-header'>
        <h1>All Customers</h1>
      </div>
      <div className='table-container'>
        <div className='table-header'>
          <div className='flex-1'>Name</div>
          <div className='flex-1'>Organization</div>
          <div className='flex-2'>Email</div>
          <div className='flex-1'>Markup Tier</div>
          <div className='flex-1'>Active Account</div>
        </div>
        <div className='table-body'>
          {users.map((user) =>
            <div className='table-row' key={user._id}>
              <div className='flex-1'><Link to={`/admin/customer/${user._id}`}>{user.name}</Link></div>
              <div className='flex-1'>{user.organization}</div>
              <div className='flex-2'>{user.email}</div>
              <div className='flex-1'>{user.markupLevel ? user.markupLevel : 'Standard'}</div>
              <div className='flex-1'>
                {user.password ? 'Yes' : 'No'}
                {!user.password && <div className='smaller fainter-one'>{user.invited && <span>Invited {moment(user.invitedData).format('LL')}</span>}</div>}
                {!user.password && <div className='smaller mt-xs'><a onClick={() => { sendUserInvite(user.email, getUsers) }}>Send an Invite</a></div>}
              </div>
            </div>
          )}
          {users.length == 0 && <div className='mt-large'><em>There are no customers yet.</em></div>}

        </div>
      </div>
    </div>
  )
}
