import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextControl from '../../components/TextControl';

export default function FirstTimeUser({ sessionValid }) {
  const [email, setEmail] = useState('');

  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [checking, setChecking] = useState(true);
  const [valid, setValid] = useState(false);

  document.title = 'Setup Your Account | Chatwin Printing';
  document.querySelector('meta[name="description"]').setAttribute('content', 'Create your password');


  let navigate = useNavigate();

  const submit = async () => {
    if (!email) {
      window.CPA.message({ type: 'error', label: 'error', text: 'You have to enter a valid email', temp: true });
    } else if (pass !== confirmPass) {
      window.CPA.message({ type: 'error', label: 'error', text: 'Passwords must match!', temp: true });
    } else {
      let user = {
        email: email,
        pass: pass
      }
      let data = await window.CPA.makeRequest('set-account-password', 'POST', user);
      if (data.ok) {
        window.CPA.message({ type: 'success', label: 'success', text: 'Password set! You can now log in.', temp: true });
        navigate('/login');
      }
    }
  }

  const getUser = async () => {
    let token = new URLSearchParams(window.location.search).get("token");
    if (!token) {
      alert('None');
      setChecking(false);
      setValid(false);
      return;
    }
    let data = await window.CPA.makeRequest(`parse-token?parse=${token}`, 'GET');
    if (data && !data.invalid && data.email) {
      setEmail(data.email);
      setChecking(false);
      setValid(true);
    } else {
      setChecking(false);
      setValid(false);
    }
  }

  useEffect(() => {
    getUser();
  }, []);


  return (
    <div>
      <div className='page-header'>
        <h1>Create Your Account Password</h1>
      </div>
      {checking &&
        <p>Checking for access</p>
      }
      {!checking && !valid &&
        <p>Your token is not valid. Please contact us for assistance</p>
      }
      {!checking && valid &&
        <div>
          <TextControl
            value={email}
            change={(value) => { setEmail(value) }}
            label={`Account Email`}
            valid={false}
            type='text'
            disabled={true}
            bypass={true}
            description={''}
          />
          <TextControl
            value={pass}
            change={(value) => { setPass(value) }}
            label={`New Password`}
            valid={false}
            type='password'
            bypass={true}
            required={true}
            description={''}
          />

          <TextControl
            value={confirmPass}
            change={(value) => { setConfirmPass(value) }}
            label={`Confirm Password`}
            valid={false}
            type='password'
            bypass={true}
            required={true}
            description={''}
          />

          <button className='primary' onClick={submit}>Set Password</button>
        </div>
      }
    </div>
  )
}
