import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import moment from 'moment';
import { ReactComponent as PDF } from '../../icons/pdf.svg';
import { ReactComponent as Book } from '../../icons/book.svg';

export default function AccountProjects({ sessionValid, roles }) {
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(0);
  const [per, setPer] = useState(20);

  const getProjects = async () => {
    const data = await window.CPA.makeRequest('list-user-projects', 'GET');
    if (data) {
      setProjects(Object.values(data));
    }
  }

  document.title = 'My Projects | Chatwin Printing';


  useEffect(() => {
    getProjects();
  }, []);

  if (!sessionValid) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  }

  return (
    <div className='card-container'>
      <div className='page-header'>
        <h1>My Projects</h1>
        <div>
          <Link to='/account/new-project'><button className='primary linkish'>New Project</button></Link>
        </div>
      </div>
      <div className='table-container'>
        <div className='table-header'>
          <div className='flex-1'>Title</div>
          <div className='flex-1'>Author</div>
          <div className='flex-1'>Author</div>
          <div className='flex-1'>Date Created</div>
        </div>
        <div className='table-body'>
          {projects.map((project) =>
            <div className='table-row' key={project._id}>
              <div className='flex-1'><Link to={`/account/project/${project._id}`}>{project.title}</Link></div>
              <div className='flex-1'>{project.author}</div>
              <div className='flex-1'>{project.type}</div>
              <div className='flex-1'>{moment(project.created).format('LLL')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
