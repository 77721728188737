import React, { useState, useEffect } from 'react';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import SelectControl from '../../components/SelectControl';
import BinaryControl from '../../components/BinaryControl';
import { updateObject, updateSubObject } from '../../Utils';

const defaultCost = {
    'description': '',
    'amount': '',
    'unit': false,
    'markedUp': true
};

export default function EditRun({ run, edition, save, close }) {
    const [updatedRun, setUpdatedRun] = useState(run);
    const [newCost, setNewCost] = useState(defaultCost);

    const addCost = () => {
        const newRun = { ...updatedRun };
        if (!newRun.additionalCosts) {
            newRun.additionalCosts = [];
        }
        newRun.additionalCosts.push(newCost);
        setUpdatedRun(newRun);
        setNewCost(defaultCost);
    }

    const removeCost = (index) => {
        const newRun = { ...updatedRun };
        newRun.additionalCosts.splice(index, 1);
        setUpdatedRun(newRun);
    }

    return (
        <div className='modal-container'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Editing Print Run Data for {edition.title}</h3>
                </div>
                <div className='modal-body flex sb'>
                    <div>
                        <NumberControl
                            value={updatedRun.quantity}
                            change={(value) => {
                                updateObject(updatedRun, "quantity", parseInt(value), setUpdatedRun);
                            }}
                            label={"Print Run Quantity"}
                            valid={false}
                            min={20}
                            max={1000}
                            step="1"
                        />
                    </div>
                    <div>
                        <h4>Add a Cost</h4>
                        <div className='smaller mb-med'>Need to add a cost that will apply to any future print runs of this project? Edit the Edition instead.</div>
                        <TextControl
                            value={newCost.description}
                            change={(value) => {
                                updateObject(newCost, "description", value, setNewCost);
                            }}
                            label={`Description`}
                            valid={false}
                            type="text"
                            bypass={false}
                            description={""}
                        />
                        <NumberControl
                            value={newCost.amount}
                            change={(value) => {
                                updateObject(newCost, "amount", value, setNewCost);
                            }}
                            label={"Amount"}
                            valid={false}
                            min={0}
                            step=".01"
                        />

                        <BinaryControl
                            value={newCost.unit}
                            change={(value) => {
                                updateObject(newCost, "unit", value, setNewCost);
                            }}
                            label={"Is this cost per-unit?"}
                        />

                        <BinaryControl
                            value={newCost.markedUp}
                            change={(value) => {
                                updateObject(newCost, "markedUp", value, setNewCost);
                            }}
                            label={"Should markup apply to this cost?"}
                        />

                        {newCost.amount && newCost.description && <button className='secondary' onClick={addCost}>Add Cost</button>}
                        <hr />
                        <h4>Added Costs</h4>

                        {updatedRun.additionalCosts && updatedRun.additionalCosts.map((cost, index) =>
                            <div key={cost.description + index} className='flex sb'>
                                <div>{cost.description} ${cost.amount.toFixed(2)} {cost.unit ? '/unit' : ''}</div><div onClick={() => { removeCost(index) }}>x</div>

                            </div>
                        )}
                        {!updatedRun.additionalCosts && <span>n/a</span>}
                    </div>

                </div>
                <div className='modal-options'>
                    <button className='primary' onClick={() => { save(updatedRun) }}>Update and Recalculate</button>
                    <button className='secondary' onClick={() => { close(false) }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}


