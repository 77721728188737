import React, { useEffect, useState } from 'react';

const useForceUpdate = () => useState()[1];

export default function FileControl({ reference, name = '', image = false, label = '', help = '', valid = true, type = 'text', classlist = '', disabled = false, accept = '', multiple = false }) {
  const forceUpdate = useForceUpdate();

  return (
    <div className={`text-control ${classlist}`}>
      {label && <label for={name} className='form-label'>{label}</label>}
      <input id={name} onChange={forceUpdate} disabled={disabled ? 'disabled' : ''} type='file' className={`${valid && 'valid'}`} accept={accept} ref={reference} multiple={multiple ? 'multiple' : false} />
      {help && <div className='form-help'>Accepted file types: {accept}</div>}
    </div>
  )
}
