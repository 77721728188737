import React, { useEffect, useState } from 'react';

export default function NumberControl({
  value,
  change,
  label = '',
  help = '',
  name = '',
  placeholder = '',
  valid = true,
  type = 'number',
  classlist = '',
  disabled = false,
  min = 0,
  max = null,
  step = 1,
  important = '' }) {


  return (
    <div className='text-control'>
      {label && <label for={name} className='form-label'>{label}</label>}
      {important && <div className='form-important'>{important}</div>}
      <input
        id={name}
        disabled={disabled ? 'disabled' : ''}
        step={step}
        type='number'
        min={min}
        className={`${valid && 'valid'} ${classlist}`}
        max={max}
        placeholder={placeholder}
        value={value}
        onChange={(e) => { change(parseFloat(e.target.value)) }} />
      {help && <div className='form-help'>{help}</div>}
    </div>
  )
}
