import React, { useState, useEffect, useRef } from 'react';
import TextControl from '../../components/TextControl';
import SelectControl from '../../components/SelectControl';
import BinaryControl from '../../components/BinaryControl';
import FileControl from '../../components/FileControl';
import { updateObject, getShippingTotal, uploadFile, Loading, sendEmail } from '../../Utils';
import PaymentForm from './checkout.js';


export default function Payment({ edition, run, close, getProject }) {
  let [amount, setAmount] = useState(0);
  let [collecting, setCollecting] = useState(false);

  const sendSuccess = async () => {
    let newRun = { ...run };
    newRun.amountPaid += amount;
    if (!newRun.events) {
      newRun.events = [];
    }
    newRun.events.push({
      type: 'payment',
      description: `${window.CPA.user.name} paid outstanding balance of $${amount.toFixed(2)}`,
      user: window.CPA.user.id,
      date: new Date().toString()
    });
    let rundata = await window.CPA.makeRequest('save-run', 'POST', newRun);
    getProject();
    window.CPA.message({ type: 'success', temp: true, text: 'Your outstanding balance has been paid', label: 'Success!' });
    let data = {
      to: null,
      message: `Just a heads up that ${window.CPA.user.name} has paid the oustanding balance for their <a href="https://chatwinprinting.com/admin/projects/${run._id}">print run of ${edition.title}</a>. It can now be shipped when ready.`,
      subject: `Outstanding balance paid for ${edition.title}`,
    }
    sendEmail(data.to, data.message, data.subject);
    close(-1);
  }

  useEffect(() => {
    setAmount(run.total - run.amountPaid);
  }, [])


  return (
    <div className='modal-container'>
      <div className='modal-content'>
        <div className='modal-header flex sb'>
          <h3>Pay Outstanding Balance</h3>
        </div>
        <div className='modal-body flex sb'>
          <p>In order for your print job to ship, you must pay the outstanding balance of <strong>${amount.toFixed(2)}</strong></p>
        </div>
        {amount > 0 &&
          <PaymentForm amount={amount} setCollecting={setCollecting} sendSuccess={sendSuccess} />
        }
        {collecting && <span><Loading /> Processing...</span>}
        {!collecting && <button className='secondary linkish' onClick={close}>Cancel</button>}

      </div>
    </div >
  )
}


