import React, { useState, useEffect } from 'react';
import ContactForm from '../../components/ContactForm';
import { Link } from 'react-router-dom';

export default function Homepage() {

  document.title = 'Chatwin Printing | Home';
  document.querySelector('meta[name="description"]').setAttribute('content', 'Seattle-based printers specializing in trade paperbacks, fine art books, posters, broadsides, and more.');

  const bw = ['#171718',
    '#2b2c2d',
    '#424243',
    '#595a5b',
    '#717374',
    '#8b8c8e',
    '#a5a7a9',
    '#c0c3c5',
    '#dcdfe1',
    '#f8fcfe'];

  const colors = ['#51b7d8',
    '#e5017e',
    '#e9dc22',
    '#000',
    '#312681',
    '#00953b',
    '#e50625',
    '#22282f',
    '#2b2c2d',
    '#df3e28'];

  return (
    <div>
      <div className='homepage-grid'>
        <div className='homepage-grid__item--wide'>
          <div className='homepage-grid__text'>
            <h2>We're not <span className='orange'>just</span> printers...</h2>
            <p>Our printing team has extensive experience in the production of quality books, posters, and quality art prints. We specialize in bringing ideas to life on the page. And we'd love to help you make your project a reality.</p>
            <div className='flex gap-med mt-large'>
              <Link to='/get-a-printing-quote'><button className='primary bigger'>Get a Quote</button></Link>
            </div>
          </div>
          <div className='homepage-grid__gradient'></div>
          <div className='homepage-grid__noise' style={{ backgroundImage: 'url(./paper1.png)' }}></div>
          <video autoplay='true' loop muted>
            <source src="./ChatwinNew.mp4" type="video/mp4" alt='Video showing pages coming off a digital printer, alternating with footage of a letterpress rolling ink across a surface.' />
          </video>
          <div className='banner-right'></div>
          <div className='crop-mark top-left'></div>
          <div className='crop-mark top-right'></div>
          <div className='crop-mark bottom-left'></div>
          <div className='crop-mark bottom-right'></div>
          <div className='bleed-mark top-left'></div>
          <div className='bleed-mark top-right'></div>
          <div className='bleed-mark bottom-left'></div>
          <div className='bleed-mark bottom-right'></div>
          <div className='homepage-grid__gradient'></div>
          <div className='registration-bar bw'>{bw.map((color) => <div className='registration-step' key={color} style={{ backgroundColor: color }}></div>)}</div>
          <div className='registration-bar color'>{colors.map((color) => <div className='registration-step' key={color} style={{ backgroundColor: color }}></div>)}</div>
        </div>
      </div>
      <div className='homepage-grid'>
        <div className='homepage-grid__item'>
          <img src='./splashalt1.png' alt='A book open showing a colorful blue and red illustration opposite text.' />
        </div>
        <div className='homepage-grid__item'>
          <img src='./splashalt3.png' alt='A book spread open revealing the full cover.' />
        </div>

        <div className='homepage-grid__item'>
          <img src='./splashalt7.png' alt='An illustrated poster of an indigenous woman. ' />
        </div>
        <div className='homepage-grid__item'>
          <img src='./stack.png' alt='A stack of magazines with an astrolabe on the cover bearing the title Firmament' />
        </div>
        <div className='homepage-grid__item home-pitch'>
          <h3>"A book is a dream you hold in your hands"</h3>
          <p>From crisp black and white text, to full-color art books, and stunning artist prints and posters, we have everything you need to bring your vision to life. Offering a wide selection of printing, binding, and design options, we strive to be the quality, full-service choice for publishers, designers, aspiring and established authors and artists.</p>

          <p>For PNW publishers, designers, artists, and creators, we offer the ability for you to visit our friendly Seattle facility for real-time proofing, revision and in-person color checks.</p>

          <p>At Chatwin, we make everything right here in Seattle, and you are always welcome to come and pick your order up from us in-person.</p>

        </div>
        <div className='homepage-grid__item'>
          <img src='./splashalt9.png' alt='An illustrated poster that reads A Book Is A Dream You Hold In Your Hands' />
        </div>
        <div className='homepage-grid__item'>
          <img src='./splashalt6.png' alt='A cloth-bound book titled The Man With Bees in His Beard' />
        </div>

        <div className='homepage-grid__item'>
          <img src='./splashalt10.png' alt='Closeup of a magazine page with artwork.' />
        </div>

        <div className='homepage-grid__item'>
          <img src='./splashalt2.png' alt='Book cover for A Door on the River' />
        </div>

        <div className='homepage-grid__item'>
          <img src='./splashalt5.png' alt='Photo of a pagefrom a poetry book with the title Last Letter' />
        </div>

        <div className='homepage-grid__item'>
          <img src='./splashalt8.png' alt='Illustrated poster featuring a likeness of Fernando Pessoa' />
        </div>
      </div>

      {/*<div className='print-shop mb-large card-container'>
        <h3>Take a tour of the print shop</h3>
        <div className='printer largest top-left'>
          <img src='./pro-c7210x.png' />
        </div>

        <div className='printer large top-right'>
          <img src='./c5100s.png' />
        </div>

        <div className='printer medium bottom-left'>
          <img src='./sp15.png' />
        </div>

        <div className='printer large bottom-right'>
          <img src='./sp20.png' />
        </div>

  </div>*/}

      <ContactForm />
    </div >
  )
}
