import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import TextControl from '../../components/TextControl';
import TextAreaControl from '../../components/TextAreaControl';
import SelectControl from '../../components/SelectControl';
import moment from 'moment';
import { updateObject, updateSubObject } from '../../Utils.js';

export default function AccountUserEdit({ sessionValid, roles }) {
  const [user, setUser] = useState({});

  const defaultAddress = {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
  };

  document.title = 'Edit User | Chatwin Printing'


  const getUser = async () => {
    const data = await window.CPA.makeRequest(`user`, 'GET');
    if (data) {
      data.address = data.address ? data.address : defaultAddress;
      setUser(data);
    }
  }

  const saveUser = async () => {
    const data = await window.CPA.makeRequest(`user`, 'POST', user);
    window.CPA.message({ type: 'success', temp: true, text: 'Profile updated', label: 'Success' });

    getUser();
  }


  useEffect(() => {
    getUser();
  }, []);

  if (!user._id || !sessionValid) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  }

  return (
    <div className='card-container'>
      <div className='page-header'>
        <div>
          <h1>My Profile</h1>
          <div>Username: {user.email}</div>
        </div>
      </div>

      <div className='flex sb'><h3>My Details</h3></div>

      <TextControl
        value={user.name}
        change={(value) => {
          updateObject(user, 'name', value, setUser);
        }}
        label={`Name`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.organization}
        change={(value) => {
          updateObject(user, 'organization', value, setUser);
        }}
        label={`Organization`}
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.phone}
        change={(value) => {
          updateObject(user, 'phone', value, setUser);
        }}
        label={`Phone`}
        valid={false}
        type="text"
        bypass={false}
        description={""}
      />

      <TextAreaControl
        value={user.description}
        change={(value) => {
          updateObject(user, "description", value, setUser);
        }}
        label={"Tell us about yourself or your organization..."}
      />


      <div className='flex sb'><h3>My Default Address</h3></div>

      <TextControl
        value={user.address.to}
        change={(value) => {
          updateSubObject(user, 'address', 'to', value, setUser);
        }}
        label={`Addressee`}
        valid={false}
        type="text"

        bypass={false}
        description={""}
      />

      <TextControl
        value={user.address.attn}
        change={(value) => {
          updateSubObject(user, 'address', 'attn', value, setUser);
        }}
        label={`ATTN`}
        valid={false}
        type="text"

        bypass={false}
        description={""}
      />

      <TextControl
        value={user.address.line1}
        change={(value) => {
          updateSubObject(user, 'address', 'line1', value, setUser);
        }}
        label={`Address Line 1`}
        valid={false}
        type="text"

        bypass={false}
        description={""}
      />

      <TextControl
        value={user.address.linew}
        change={(value) => {
          updateSubObject(user, 'address', 'line2', value, setUser);
        }}
        label={`Address Line 2`}
        valid={false}
        type="text"
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.address.city}
        change={(value) => {
          updateSubObject(user, 'address', 'city', value, setUser);
        }}
        label={`City`}
        valid={false}
        type="text"
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.address.state}
        change={(value) => {
          updateSubObject(user, 'address', 'state', value, setUser);
        }}
        label={`State`}
        valid={false}
        type="text"
        bypass={false}
        description={""}
      />

      <TextControl
        value={user.address.postalCode}
        change={(value) => {
          updateSubObject(user, 'address', 'postalCode', value, setUser);
        }}
        label={`Postal Code`}
        valid={false}
        type="text"
        bypass={false}
        description={""}
      />



      <button className='primary linkish' onClick={saveUser}>Save Changes</button>

    </div>
  )
}
