import Reactt, { useEffect, useState, useRef } from 'react';
import NumberControl from '../NumberControl';
import SelectControl from '../SelectControl';
import TextAreaControl from '../TextAreaControl';
import BinaryControl from '../BinaryControl';
import MultiSelect from '../MultiSelect';
import { updateObject, updateSubObject, getQuote, getSpine, getPapers, uploadFile } from "../../Utils";


export default function QuoteGenerator({ edition, run, customer, setEdition, setRun, hasChanges, setHasChanges }) {
  const [costConfig, setCostConfig] = useState({});

  const [textPapers, setTextPapers] = useState([]);
  const [coverPapers, setCoverPapers] = useState([]);
  const [flatPapers, setFlatPapers] = useState([]);

  const getConfig = async () => {
    const data = await window.CPA.makeRequest("get-cost-config", "GET");
    if (data && data._id) {
      setCostConfig(data);
    }
  };

  const editionFiles = useRef(null);

  const additions = ['advanced color proofing', 'design consultation', 'letterpress cover', 'color inserts', 'foil stamps', 'French flaps']

  const sortPapers = async () => {
    const data = await getPapers();
    setTextPapers(data['Text']);
    setCoverPapers(data['Cover']);
    setFlatPapers(data['Poster/Flats']);
  };

  const getTheQuote = async () => {
    if (edition.type == '') {
      window.CPA.message({ type: 'error', temp: true, text: 'We need more information', label: 'Error' });
      return;
    } else if (!edition.trim) {
      window.CPA.message({ type: 'error', temp: true, text: 'You need to select a trim size', label: 'Error' });
      return;
    } else if (
      edition.type == "Paperback" &&
      (!edition.interiorPaper || !edition.coverPaper)
    ) {
      window.CPA.message({ type: 'error', temp: true, text: 'Please select an interior and cover stock for your book.', label: 'Error' });
      return;
    }

    let quote = await getQuote(run, edition, customer);

    let newEdition = { ...edition };
    newEdition.spine = await getSpine(newEdition);

    if (quote.needsReview) {
      quote.status = 'Quote';
      newEdition.needsReview = true;
      newEdition.reviewMessage = quote.reviewMessage;
    }

    setEdition(newEdition);
    setRun(quote);

    setHasChanges(false);
  };


  useEffect(() => {
    getConfig();
    sortPapers();
  }, []);

  return (
    <div>
      <SelectControl
        value={edition.type}
        name='edType'
        change={(value) => {
          updateObject(edition, "type", value, setEdition);
        }}
        label={"Project Type"}
        help={""}
        options={[
          {
            value: "",
            label: "Select a project type",
          },
          {
            value: "Paperback",
            label: "Paperback",
          },
          {
            value: "Flat",
            label: "Flat (posters, collated unbound, etc.)",
          },
        ]}
      />
      <SelectControl
        value={edition.trim}
        name='edTrim'
        change={(value) => {
          updateObject(edition, "trim", value, setEdition);
        }}
        label={"Trim Size"}
        help={"The dimensions of your project."}
        options={[
          {
            value: "",
            label: "--",
          },
          {
            value: "5x7",
            label: "5 x 7",
          },
          {
            value: "5.5x8.5",
            label: "5.5 x 8.5",
          },
          {
            value: "5.5x8.25",
            label: "5.5 x 8.25",
          },
          {
            value: "6x9",
            label: "6 x 9",
          },
          {
            value: "7x9",
            label: "7 x 9",
          },
          {
            value: "7x10",
            label: "7 x 10",
          },
          {
            value: "custom",
            label: "Custom",
          },
        ]}
      />
      {
        edition.trim == "custom" && (
          <div className="flex">
            <NumberControl
              name='width'
              value={edition.trimCustom.split("x")[0]}
              change={(value) => {
                let arr = [...edition.trimCustom.split("x")];
                arr.splice(0, 1, value);
                updateObject(edition, "trimCustom", arr.join("x"), setEdition);
              }}
              label={"Width (inches)"}
              valid={false}
              min={3.0}
              max={100}
              step=".01"
            />

            <NumberControl
              value={edition.trimCustom.split("x")[1]}
              name='height'
              change={(value) => {
                let arr = [...edition.trimCustom.split("x")];
                arr.splice(1, 1, value);
                updateObject(edition, "trimCustom", arr.join("x"), setEdition);
              }}
              label={"Height (inches)"}
              valid={false}
              min={3.0}
              max={100}
              step=".01"
            />
          </div>
        )
      }
      <NumberControl
        value={edition.pages}
        name='pageCount'
        change={(value) => {
          updateObject(edition, "pages", parseInt(value), setEdition);
        }}
        label={edition.type == 'Flat' ? 'Sheets' : 'Page Count'}
        valid={false}
        min={1}
        max={1000}
        step="1"
      />

      {
        edition.type == "Paperback" && (
          <div>
            <SelectControl
              name='binding'
              value={edition.binding}
              change={(value) => {
                updateObject(edition, "binding", value, setEdition);
              }}
              label={"Binding"}
              help={
                edition.binding == 'Perfect' && edition.pages < 30 ?
                  'We recommend a minimum pagecount of 30 for perfect bound books. Consider a staplebound book.' :
                  edition.binding == 'Staple' && edition.pages > 30 ?
                    'We recommend a maximum pagecount of 30 for staplebound books. Consider a perfect bound book' :
                    ''
              }
              options={[
                {
                  value: "Perfect",
                  label: "Perfect",
                },
                {
                  value: "Staple",
                  label: "Staple",
                },
              ]}
            />
            <SelectControl
              value={edition.interiorPaper}
              name='interiorPaper'
              change={(value) => {
                updateObject(edition, "interiorPaper", value, setEdition);
              }}
              label={"Interior Paper Stock"}
              options={[
                {
                  value: "",
                  label: "--",
                },
              ].concat(
                textPapers.map((paper) => {
                  return {
                    value: paper._id,
                    label: `${paper.name} (${paper.weight})`,
                  };
                })
              )}
            />

            <SelectControl
              value={edition.interiorPrinting}
              name='interiorPrinting'
              change={(value) => {
                updateObject(edition, "interiorPrinting", value, setEdition);
              }}
              label={"Interior Paper Printing"}
              options={[
                {
                  value: "blackAndWhite",
                  label: "Black & White",
                },
                {
                  value: "fourColor",
                  label: "Four Color",
                },
              ]}
            />

            <BinaryControl
              value={edition.fullBleed}
              name='fullBleed'
              change={(value) => {
                updateObject(edition, "fullBleed", value, setEdition);
              }}
              label={"Is your interior full bleed?"}
            />

            <SelectControl
              value={edition.coverPaper}
              name='coverPaper'
              change={(value) => {
                updateObject(edition, "coverPaper", value, setEdition);
              }}
              label={"Cover Stock"}
              options={[
                {
                  value: "",
                  label: "--",
                },
              ].concat(
                coverPapers.map((paper) => {
                  return {
                    value: paper._id,
                    label: `${paper.name} (${paper.weight})`,
                  };
                })
              )}
            />

            {costConfig.laminationOptions && (
              <SelectControl
                value={edition.lamination}
                name='lamination'
                change={(value) => {
                  updateObject(edition, "lamination", value, setEdition);
                }}
                label={"Cover Lamination"}
                help={"Lamination is one-sided only"}
                options={Object.keys(costConfig.laminationOptions).map((opt) => {
                  return {
                    value: opt,
                    label: opt,
                  };
                })}
              />
            )}

            <BinaryControl
              value={edition.coverTwoSided}
              change={(value) => {
                updateObject(edition, "coverTwoSided", value, setEdition);
              }}
              label={"Cover printed on both sides?"}
            />
          </div>
        )
      }
      {
        edition.type == 'Flat' &&
        <div>

          <SelectControl
            value={edition.flatPaper}
            name='flatPaper'
            change={(value) => {
              updateObject(edition, "flatPaper", value, setEdition);
            }}
            label={"Paper Stock"}
            options={[
              {
                value: "",
                label: "--",
              },
            ].concat(
              flatPapers.map((paper) => {
                return {
                  value: paper._id,
                  label: `${paper.name} (${paper.weight})`,
                };
              })
            )}
          />

          {costConfig.laminationOptions && (
            <SelectControl
              name='edLamination'
              value={edition.lamination}
              change={(value) => {
                updateObject(edition, "lamination", value, setEdition);
              }}
              label={"Cover Lamination"}
              help={"Lamination is one-sided only"}
              options={Object.keys(costConfig.laminationOptions).map((opt) => {
                return {
                  value: opt,
                  label: opt,
                };
              })}
            />
          )}

          <SelectControl
            value={edition.flatPrinting}
            name='flatPrinting'
            change={(value) => {
              updateObject(edition, "flatPrinting", value, setEdition);
            }}
            label={"Print Colors"}
            options={[
              {
                value: "blackAndWhite",
                label: "Black & White",
              },
              {
                value: "fourColor",
                label: "Four Color",
              },
            ]}
          />

          <BinaryControl
            value={edition.flatTwoSided}
            change={(value) => {
              updateObject(edition, "flatTwoSided", value, setEdition);
            }}
            label={"Are pages printed on both sides?"}
          />

          {edition.pages > 1 && <BinaryControl
            value={edition.flatTwoSided}
            change={(value) => {
              updateObject(edition, "flatCollated", value, setEdition);
            }}
            label={"Do you need the job collated?"}
          />}
        </div>
      }
      <NumberControl
        value={run.quantity}
        name='quantity'
        change={(value) => {
          updateObject(run, "quantity", parseInt(value), setRun);
        }}
        label={"Number of Copies"}
        valid={false}
        min={10}
        max={1000000}
        step="1"
      />

      <MultiSelect
        classList=''
        values={edition.customerRequests ? edition.customerRequests : []}
        change={(value) => { updateObject(edition, "customerRequests", value, setEdition) }}
        choices={additions}
        label={'Interested in any extras?'}
        help={'Select any features you want for your project.'}
      />

      {
        edition.needsReview && <TextAreaControl
          value={edition.customerNotes}
          change={(value) => {
            updateObject(edition, "customerNotes", value, setEdition);
          }}
          label={"Need something else? Tell us about it!"}
        />
      }

      {(hasChanges || !run.total || run.total === 0) && <button className='primary linkish' onClick={getTheQuote}>Get Quote</button>}
    </div>
  )
}