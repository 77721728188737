import React, { useState, useEffect } from 'react';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import SelectControl from '../../components/SelectControl';
import BinaryControl from '../../components/BinaryControl';
import { updateObject, updateSubObject, getQuote } from '../../Utils';


export default function ReprintQuote({ edition, run, customer, close, save }) {
  const [newRun, setNewRun] = useState(run);
  const [isSaveable, setIsSaveable] = useState(false);

  const calc = async () => {
    const quote = await getQuote(newRun, edition, customer);
    setNewRun(quote);
    setIsSaveable(true);
  }
  useEffect(() => {
    setIsSaveable(false);
  }, [newRun])

  return (
    <div className='modal-container'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h3>Reprint Quote</h3>
        </div>
        <div className='modal-body'>

          <NumberControl
            value={newRun.quantity}
            change={(value) => {
              updateObject(newRun, "quantity", parseInt(value), setNewRun);
            }}
            label={"How many do you need?"}
            valid={false}
            min={20}
            max={1000}
            step="1"
          />

          {isSaveable && newRun.total && newRun.total > 0 &&
            <div className='mb-med'>
              <div className='estimated-price'><strong>Estimated Price:</strong> ${newRun.total.toFixed(2)} <span className='unit'>(${(newRun.total / newRun.quantity).toFixed(2)} / unit)</span></div>
              {run.needsReview && <p>Note: You've selected options that may affect the above price. Click save and we'll review your project and provide an updated quote within two business days!</p>}
              {!run.neewsReview && <p>Save this quote and place an order.</p>}
            </div>
          }

        </div>
        <div className='modal-options gap-small flex'>
          {!isSaveable && <button className='primary' onClick={calc}>Get Reprint Quote</button>}
          {isSaveable && <button className='primary' onClick={() => { save(newRun) }}>Save</button>}
          <button className='secondary' onClick={() => { close(false) }}>Cancel</button>
        </div>
      </div>
    </div>
  )
}


