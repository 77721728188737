import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TextControl from '../../components/TextControl';

export default function AdminUserCreate({ sessionValid }) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const navigate = useNavigate();

  document.title = 'Create a New User | Chatwin Printing';


  const submit = async () => {
    if (!email) {
      window.CPA.message({ type: 'error', label: 'error', text: 'You have to enter a valid email', temp: true });
    } else if (!name) {
      window.CPA.message({ type: 'error', label: 'error', text: 'You have to enter a name', temp: true });
    } else {
      let user = {
        email: email,
        name: name,
        markupLevel: 'Standard',
        createdData: new Date().toString(),
        roles: {
          customer: true,
          admin: false,
        }
      }
      let data = await window.CPA.makeRequest('user', 'POST', user);
      if (data.ok) {
        window.CPA.message({ type: 'success', label: 'success', text: 'User created successfully!', temp: true });
        navigate(`/admin/customer/${data.id}`);
      }
    }
  }

  return (
    <div>
      <div className='page-header'>
        <h1>Create a User Account</h1>
      </div>
      <TextControl
        value={email}
        change={(value) => { setEmail(value) }}
        label={`Account Email`}
        valid={false}
        type='text'
        required={true}
        bypass={true}
        description={''}
      />

      <TextControl
        value={name}
        change={(value) => { setName(value) }}
        label={`Account Name`}
        valid={false}
        type='text'
        required={true}
        bypass={true}
        description={''}
      />

      <button className='primary' onClick={submit}>Create</button>
    </div>
  )
}
