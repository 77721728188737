import React, { useState, useEffect } from 'react';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import SelectControl from '../../components/SelectControl';
import BinaryControl from '../../components/BinaryControl';
import MultiSelect from '../../components/MultiSelect';
import { updateObject, updateSubObject } from '../../Utils';

const defaultPaper = {
    name: '',
    weight: '60lb',
    type: ['Text'],
    costPerSheet: 0.00,
    sku: '',
    attributes: {
        'FSC': false,
        'SFI': false,
        'PEFC': false,
        '100% post-consumer waste': false
    },
    available: true,
    caliper: 0.00,
    notes: ''
}

export default function PaperEditor({ id = null, close }) {
    const [paper, setPaper] = useState(defaultPaper);
    const [uploading, setUploading] = useState(false);

    const submit = async () => {
        let data = await window.CPA.makeRequest('save-paper', 'POST', paper);
        if (data.ok) {
            window.CPA.message({ type: 'success', label: 'success', text: 'Paper created uccessfully!', temp: true });
            close(false);
        } else {
            window.CPA.message({ type: 'error', temp: true, text: 'There was a problem saving the data. Call Josh.', label: 'Error' })
        }
    }

    const getPaper = async () => {
        let data = await window.CPA.makeRequest(`get-paper?id=${id}`, 'GET');
        setPaper(data);
    }

    useEffect(() => {
        if (!id) {
            setPaper(defaultPaper);
        } else {
            getPaper();
        }
    }, [])

    return (
        <div className='modal-container'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Adding New Paper</h3>
                </div>
                <div className='modal-body flex sb'>
                    <div className='half'>
                        <TextControl
                            value={paper.name}
                            change={(value) => { updateObject(paper, 'name', value, setPaper) }}
                            label={`Paper Name`}
                            valid={false}
                            type='text'
                            required={true}
                            bypass={false}
                            description={''}
                        />

                        <BinaryControl
                            value={paper.available}
                            change={(value) => { updateObject(paper, 'available', value, setPaper) }}
                            label={'Is the paper available?'}
                            disabled={uploading}
                        />

                        <TextControl
                            value={paper.weight}
                            change={(value) => { updateObject(paper, 'weight', value, setPaper) }}
                            label={`Weight`}
                            valid={false}
                            type='text'
                            required={true}
                            bypass={false}
                            description={''}
                        />

                        <TextControl
                            value={paper.sku}
                            change={(value) => { updateObject(paper, 'sku', value, setPaper) }}
                            label={`SKU`}
                            valid={false}
                            type='text'
                            required={true}
                            bypass={false}
                            description={''}
                        />

                        <MultiSelect
                            classList=''
                            values={paper.type}
                            change={(value) => { updateObject(paper, "type", value, setPaper) }}
                            choices={['Text', 'Cover', 'Poster/Flats', 'Photo', 'Letterpress']}
                            label={'This paper is good for...'}
                        />
                        <NumberControl
                            value={paper.caliper}
                            change={(value) => { updateObject(paper, 'caliper', value, setPaper) }}
                            label={'Caliper Thickness'}
                            help={'Make this accurate, as we will use this to produce spine templates'}
                            valid={false}
                            disabled={uploading}
                            step='.0001'
                        />
                        <NumberControl
                            value={paper.costPerSheet}
                            change={(value) => { updateObject(paper, 'costPerSheet', value, setPaper) }}
                            label={'Cost Per Sheet'}
                            valid={false}
                            disabled={uploading}
                            step='.01'
                        />
                    </div>
                    <div className='half'>
                        <BinaryControl
                            value={paper.attributes.FSC}
                            change={(value) => { updateSubObject(paper, 'attributes', 'FSC', value, setPaper) }}
                            label={'FSC Certified'}
                            disabled={uploading}
                        />
                        <BinaryControl
                            value={paper.attributes.SFI}
                            change={(value) => { updateSubObject(paper, 'attributes', 'SFI', value, setPaper) }}
                            label={'SFI Certified'}
                            disabled={uploading}
                        />
                        <BinaryControl
                            value={paper.attributes.PEFC}
                            change={(value) => { updateSubObject(paper, 'attributes', 'PEFC', value, setPaper) }}
                            label={'PEFC Certified'}
                            disabled={uploading}
                        />
                        <BinaryControl
                            value={paper.attributes['100% post-consumer waste']}
                            change={(value) => { updateSubObject(paper, 'attributes', '100% post-consumer waste', value, setPaper) }}
                            label={'100% post-consumer waste'}
                            disabled={uploading}
                        />
                    </div>

                </div>
                <div className='modal-options flex gap-med'>
                    <button className='primary linkish' onClick={submit}>{paper._id ? 'Update' : 'Create'}</button>
                    <button className='secondary linkish' onClick={() => { close(false) }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}


