import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import { updateObject, updateSubObject } from '../../Utils';

export default function AdminOptions({ sessionValid, roles }) {
  const [originalConfig, setOriginalConfig] = useState({});
  const [newConfig, setNewConfig] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  document.title = 'Other Options | Chatwin Printing'


  const getConfig = async () => {
    const data = await window.CPA.makeRequest('get-other-config', 'GET');
    if (data && data._id) {
      setNewConfig(data);
      setOriginalConfig(data);
      setHasChanges(false);
    }
  }

  const saveConfig = async () => {
    const data = await window.CPA.makeRequest('save-other-config', 'POST', newConfig);
    if (data.ok) {
      window.CPA.message({ type: 'success', temp: true, text: 'Options saved!', label: 'Success' });

      let newerConfig = { ...newConfig };
      newerConfig._rev = data.rev;
      setNewConfig(newerConfig);
      setOriginalConfig(newerConfig);
      setHasChanges(false);
    }

  }

  const undo = () => {
    setNewConfig(originalConfig);
    setHasChanges(false);
  }

  useEffect(() => {
    setHasChanges(true);
  }, [newConfig])


  useEffect(() => {
    getConfig();
  }, []);

  if (!sessionValid && !roles.admin) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  } else if (!roles.admin) {
    return (<div className='card-container'>You do not have permission to view this page.</div>)
  }

  return (
    <div className='card-container'>
      <div className='page-header'>
        <h1>Other Options</h1>
      </div>
      {newConfig._id && <div>
        <TextControl
          value={newConfig.emailList}
          change={(value) => { updateObject(newConfig, 'emailList', value, setNewConfig) }}
          label={'Email List for Notifications'}
          help={'Enter a comma-separated list of email addresses'}
        />
      </div>}
      <div className='flex gap-med'>
        <button className='primary linkish' onClick={saveConfig}>Save Changes</button>
        {hasChanges && <button className='secondary linkish' onClick={undo}>Discard Changes</button>}
      </div>
    </div>
  )
}
