import React, { useState, useEffect, useRef } from "react";
import { Link, useInRouterContext, useNavigate } from "react-router-dom";
import TextControl from "../../components/TextControl";
import TextAreaControl from "../../components/TextAreaControl";
import FileControl from '../../components/FileControl';
import QuoteGenerator from '../../components/QuoteGenerator';

import { updateObject, updateSubObject, getQuote, getSpine, getPapers, uploadFile } from "../../Utils";


const defaultEdition = {
  title: "",
  author: "",
  type: "",
  binding: "Perfect",
  interiorPaper: "",
  flatPaper: "",
  flatPrinting: "blackAndWhite",
  flatTwoSided: false,
  flatCollated: false,
  needShipping: true,
  needsReview: false,
  pages: 100,
  coverPaper: "",
  interiorPrinting: "blackAndWhite",
  lamination: "Matte Soft-Touch",
  fullBleed: false,
  coverTwoSided: false,
  trim: "5x7",
  trimCustom: "5x7",
  needsReview: false,
  customerNotes: '',
  customerRequests: '',
  created: new Date().toString()
};

const defaultRun = {
  quantity: 100,
  firstRun: true,
  created: new Date().toString(),
  events: []
}

const defaultCustomer = {
  name: '',
  organization: '',
  email: '',
  markupLevel: 'Standard',
  roles: {
    customer: true
  },
}

// Quote / Ordered / In Production / Shipped

export default function NewProject({ sessionValid, roles }) {
  const [edition, setEdition] = useState(defaultEdition);
  const [run, setRun] = useState(defaultRun)
  const [customer, setCustomer] = useState(defaultCustomer);
  const [uploading, setUploading] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [title, setTitle] = useState('');

  const navigate = useNavigate();

  document.title = 'Get a Printing Quote | Chatwin Printing'
  document.querySelector('meta[name="description"]').setAttribute('content', 'Tell us about your project and get an instant estimate!');


  let editionFiles = useRef(null);

  const saveTheQuote = async () => {
    if (!title) {
      window.CPA.message({ type: 'error', temp: true, text: 'Project needs a title', label: 'Error' });
      return;
    } else if (!customer.name || !customer.email) {
      window.CPA.message({ type: 'error', temp: true, text: 'We need your name and email', label: 'Error' });
      return;
    }

    run.events.push({
      type: 'statusChange',
      description: `Project created by ${customer.name}`,
      user: '',
      date: new Date().toString()
    })

    let body = {
      customer: customer,
      run: run,
      edition: edition,
    }

    body.edition.title = title;
    setUploading(true);

    if (editionFiles.current && editionFiles.current.files && editionFiles.current.files.length > 0) {

      window.CPA.message({ type: 'info', label: 'Give us a moment', text: 'Hold tight! We are uploading your files...', temp: true });
      let newFiles = [];
      let toUpload = editionFiles.current.files;
      for (let i = 0; i < toUpload.length; i++) {
        let file = await uploadFile(toUpload[i]);
        if (file.status == 'good') {
          newFiles.push(file);
        }
      }
      body.edition.files = newFiles;
    }

    let data = await window.CPA.makeRequest('create-new-quote', 'POST', body);
    if (data.ok) {
      window.CPA.message({ type: 'success', label: 'success', text: 'Your quote is all good!', temp: true });
      setUploading(false);
      navigate(`/quote-confirmation`);
    }

  }

  useEffect(() => {
    setHasChanges(true);
  }, [run, edition]);


  return (
    <div className='card-container'>
      <div className="page-header">
        <h1>Get a Printing Quote</h1>
      </div>
      <p>Tell us about your project! We'll give you an instant quote (good for 72 hours). If you like what you see, or need a little something outside-the-box, apply for an account and we'll be in touch within two business days.</p>
      {!sessionValid && <p className='mb-large'>Already have an account? <Link to='/login?return=/account/new-project'>Log in</Link>.</p>}
      {sessionValid && <p className='mb-large'>Looks like you already have an account! <Link to='/account/new-project'>Go here</Link> to create a new project.</p>}
      {!sessionValid && <QuoteGenerator edition={edition} run={run} hasChanges={hasChanges} setHasChanges={setHasChanges} setEdition={setEdition} customer={customer} setRun={setRun} />}
      {!hasChanges && run.total &&
        <div className='mb-med'>
          <div className='estimated-price'><strong>Estimated Price:</strong> ${run.total.toFixed(2)} <span className='unit'>(${(run.total / run.quantity).toFixed(2)} / unit)</span></div>
          <div className='small-print mt-small'>Price does not include shipping (if needed) or sales tax.</div>
          {run.needsReview && <p className='bold red'>Note: You've selected options that may affect the above price. If you save your quote, we will review it, and send you an adjusted quote.</p>}
          <p>Want to continue? Tell us a bit more about yourself and we'll be in touch within two business days. Once we approve your account, you can place self-service orders in the future!</p>
          <div>

            <TextControl
              value={title}
              change={setTitle}
              label={`Project Title`}
              valid={false}
              type="text"
              name='projectTitle'
              required={true}
              bypass={false}
              description={""}
            />

            <TextControl
              value={customer.name}
              change={(value) => {
                updateObject(customer, "name", value, setCustomer);
              }}
              label={`Contact Name`}
              valid={false}
              type="text"
              name='contactName'
              required={true}
              bypass={false}
              description={""}
            />

            <TextControl
              value={customer.email}
              change={(value) => {
                updateObject(customer, "email", value, setCustomer);
              }}
              label={`Contact Email`}
              valid={false}
              type="text"
              name='contactEmail'
              required={true}
              bypass={false}
              description={""}
            />

            <TextControl
              value={customer.organization}
              change={(value) => {
                updateObject(customer, "organization", value, setCustomer);
              }}
              label={`Contact Organization`}
              valid={false}
              type="text"
              name='contactOrg'
              bypass={false}
              description={""}
              help={'If you are representing a press, design firm, etc.'}
            />

            <TextAreaControl
              value={customer.description}
              name='description'
              change={(value) => {
                updateObject(customer, "description", value, setCustomer);
              }}
              label={"Tell us about yourself or your organization..."}
            />

            <FileControl
              classlist=''
              label={'Upload Your Files (Optional)'}
              help={''}
              name='fileUpload'
              valid={false}
              reference={editionFiles}
              multiple={true}
              disabled={uploading}
              image={true}
              accept="image/png, image/jpeg, application/pdf"
            />

            <button className='primary linkish' onClick={saveTheQuote} disabled={uploading}>Save and Create Account</button>
          </div>
        </div>
      }

    </div>
  );
}
