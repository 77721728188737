import React, { useState, useEffect } from "react";
import { Link, useInRouterContext, useNavigate } from "react-router-dom";
import TextControl from "../../components/TextControl";
import SelectControl from "../../components/SelectControl";
import BinaryControl from "../../components/BinaryControl";
import NumberControl from "../../components/NumberControl";
import { updateObject, updateSubObject, getQuote, getSpine } from "../../Utils";
import SpineGenerator from '../../components/SpineGenerator';


const defaultEdition = {
  title: "",
  author: "",
  type: "Paperback",
  binding: "Perfect",
  interiorPaper: "",
  flatPaper: "",
  flatPrinting: "blackAndWhite",
  flatTwoSided: false,
  flatCollated: false,
  needShipping: true,
  needsReview: false,
  pages: 100,
  coverPaper: "",
  interiorPrinting: "blackAndWhite",
  lamination: "Matte Soft-Touch",
  fullBleed: false,
  coverTwoSided: false,
  trim: "5x7",
  trimCustom: "5x7",
  needsReview: false,
  customerNotes: '',
  customerRequests: '',
  created: new Date().toString()
};

const defaultRun = {
  quantity: 100,
  firstRun: true,
  created: new Date().toString()
}

// Quote / Ordered / In Production / Shipped

export default function SpineWidget({ sessionValid, roles }) {
  const [edition, setEdition] = useState(defaultEdition);
  const [run, setRun] = useState(defaultRun)
  const [hasChanges, setHasChanges] = useState(false);

  document.title = 'Spine Width Calculator | Chatwin Printing'

  const navigate = useNavigate();

  useEffect(() => {
    setHasChanges(true);
  }, [run, edition]);



  const getTheQuote = async () => {
    if (!edition.title) {
      return;
    } else if (!edition.trim) {
      window.CPA.message({ type: 'error', temp: true, text: 'You need to select a trim size', label: 'Error' });
      return;
    } else if (
      edition.type == "Paperback" &&
      (!edition.interiorPaper || !edition.coverPaper)
    ) {
      window.CPA.message({ type: 'error', temp: true, text: 'Please select an interior and cover stock for your book.', label: 'Error' });
      return;
    }
  };


  if (!sessionValid && !roles.admin) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  } else if (!roles.admin) {
    return (<div className='card-container'>You do not have permission to view this page.</div>)
  }

  return (
    <div className='card-container'>
      <div className="page-header">
        <h1>Spine Width Generator</h1>
      </div>

      <SpineGenerator edition={edition} run={run} hasChanges={hasChanges} setHasChanges={setHasChanges} setEdition={setEdition} customer={{ markupLevel: 'standard' }} setRun={setRun} />

      {!hasChanges && run.total && <div className='estimated-price mb-large'><strong>Estimated Price:</strong> ${run.total.toFixed(2)} <span className='unit'>(${(run.total / run.quantity).toFixed(2)} / unit)</span></div>}

      {!hasChanges && run.total &&
        <div>
          {run.needsReview &&
            <div>
              <p><span className='bold'>This project would require review</span></p>
              {run.reviewMessage && <p>{run.reviewMessage}</p>}
              {run.customerRequests && <p><span className='bold'>Customer interested in:</span> {run.customerRequests.join(', ')}</p>}
              {run.customerNotes && <p><span className='bold'>Customer says</span>: "{run.customerNotes}"</p>}
            </div>
          }
          <h3>Quote Details</h3>
          {run.quoteBreakdown.map((line) =>
            <div className='table-row' key={line.code}>
              <div className='flex-2'>{line.description}</div>
              <div className='flex-1'>${line.total.toFixed(2)}</div>
            </div>
          )}
        </div>
      }
    </div>
  );
}
