import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

function CheckoutForm({ amount, sendSuccess, setCollecting }) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setCollecting(false);
    } else {
      sendSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe} onClick={() => { setCollecting(true) }}>Submit</button>
    </form>
  )
};

export default function PaymentForm({ amount, sendSuccess, setCollecting }) {
  const [secret, setSecret] = useState('')

  const req = {
    amount: amount * 100,
  };

  const stripePromise = loadStripe('pk_test_IlR1SiW7mU7dXgwZERBG5kQg00uHni8qBs');

  const getSecret = async () => {
    let data = await window.CPA.makeRequest('create-payment-intent', 'POST', req);
    setSecret(data.clientSecret);
  }

  useEffect(() => {
    getSecret();
  }, [])

  const options = {
    clientSecret: secret,
  };

  return (
    <div className='mb-med checkout-form'>
      {secret &&
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm sendSuccess={sendSuccess} setCollecting={setCollecting} amount={amount} />
        </Elements>
      }
    </div>
  )
}