import React, { useEffect, useState } from 'react';

export default function MultiSelect({ choices = [], change, values = [], label = '', help = '', disabled = false }) {

  const updateCategories = (name) => {
    let newValues = [...values];
    let index = values.indexOf(name);
    if (index === -1) {
      newValues.push(name);
    } else {
      newValues.splice(index, 1)
    }
    change(newValues);
  }

  return (
    <div className='text-control' key={values.join('-')}>
      {label && <label className='form-label'>{label}</label>}

      {!disabled && <div>
        {choices.map((choice) =>
          <div key={choice} tabIndex={0} aria-label={choice} role='button' className={`pill email ${values.indexOf(choice) !== -1 ? 'active' : ''}`} onClick={() => { updateCategories(choice) }}>{choice}</div>
        )}
      </div>}
      {disabled && <div>
        {choices.map((choice) =>
          <div key={choice} className={`pill disabled email ${values.indexOf(choice) !== -1 ? 'active' : ''}`} >{choice}</div>
        )}
      </div>}

      {help && <div className='form-help'>{help}</div>}
    </div>
  )
}
