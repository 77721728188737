import React, { useState, useEffect } from 'react';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import SelectControl from '../../components/SelectControl';
import BinaryControl from '../../components/BinaryControl';
import { updateObject, updateSubObject, getPapers } from '../../Utils';

const defaultCost = {
    'description': '',
    'amount': '',
    'unit': false,
    'markedUp': true,
};


export default function EditEdition({ edition, save, close }) {
    const [updatedEdition, setUpdatedEdition] = useState(edition);
    const [textPapers, setTextPapers] = useState([]);
    const [coverPapers, setCoverPapers] = useState([]);
    const [costConfig, setCostConfig] = useState({});

    const [newCost, setNewCost] = useState(defaultCost);

    const addCost = () => {
        const newEdition = { ...updatedEdition };
        if (!newEdition.additionalCosts) {
            newEdition.additionalCosts = [];
        }
        newEdition.additionalCosts.push(newCost);
        setUpdatedEdition(newEdition);
        setNewCost(defaultCost);
    }

    const removeCost = (index) => {
        const newEdition = { ...updatedEdition };
        newEdition.additionalCosts.splice(index, 1);
        setUpdatedEdition(newEdition);
    }

    const getConfig = async () => {
        const data = await window.CPA.makeRequest("get-cost-config", "GET");
        if (data && data._id) {
            setCostConfig(data);
        }
    };

    const sortPapers = async () => {
        const data = await getPapers();
        console.log(data);
        setTextPapers(data['Text']);
        setCoverPapers(data['Cover']);
    }

    useEffect(() => {
        sortPapers();
        getConfig();
    }, [])




    return (
        <div className='modal-container'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h3>Editing Edition Data for {updatedEdition.title}</h3>
                </div>
                <div className='modal-body flex sb'>
                    <div>
                        <NumberControl
                            value={updatedEdition.pages}
                            change={(value) => {
                                updateObject(updatedEdition, "pages", parseInt(value), setUpdatedEdition);
                            }}
                            label={"Page Count"}
                            valid={false}
                            min={60}
                            max={1000}
                            step="1"
                        />

                        {updatedEdition.type == "Paperback" && (
                            <div>
                                <SelectControl
                                    value={updatedEdition.interiorPaper}
                                    change={(value) => {
                                        updateObject(edition, "interiorPaper", value, setUpdatedEdition);
                                    }}
                                    label={"Interior Paper Stock"}
                                    options={[
                                        {
                                            value: "",
                                            label: "--",
                                        },
                                    ].concat(
                                        textPapers.map((paper) => {
                                            return {
                                                value: paper._id,
                                                label: `${paper.name} (${paper.weight})`,
                                            };
                                        })
                                    )}
                                />

                                <SelectControl
                                    value={updatedEdition.interiorPrinting}
                                    change={(value) => {
                                        updateObject(edition, "interiorPrinting", value, setUpdatedEdition);
                                    }}
                                    label={"Interior Paper Stock"}
                                    options={[
                                        {
                                            value: "blackAndWhite",
                                            label: "Black & White",
                                        },
                                        {
                                            value: "fourColor",
                                            label: "Four Color",
                                        },
                                    ]}
                                />

                                <SelectControl
                                    value={updatedEdition.coverPaper}
                                    change={(value) => {
                                        updateObject(edition, "coverPaper", value, setUpdatedEdition);
                                    }}
                                    label={"Cover Stock"}
                                    options={[
                                        {
                                            value: "",
                                            label: "--",
                                        },
                                    ].concat(
                                        coverPapers.map((paper) => {
                                            return {
                                                value: paper._id,
                                                label: `${paper.name} (${paper.weight})`,
                                            };
                                        })
                                    )}
                                />

                                {costConfig.laminationOptions && (
                                    <SelectControl
                                        value={updatedEdition.lamination}
                                        change={(value) => {
                                            updateObject(edition, "lamination", value, setUpdatedEdition);
                                        }}
                                        label={"Cover Lamination"}
                                        help={"Lamination is one-sided only"}
                                        options={Object.keys(costConfig.laminationOptions).map((opt) => {
                                            return {
                                                value: opt,
                                                label: opt,
                                            };
                                        })}
                                    />
                                )}

                                <BinaryControl
                                    value={updatedEdition.coverTwoSided}
                                    change={(value) => {
                                        updateObject(edition, "coverTwoSided", value, setUpdatedEdition);
                                    }}
                                    label={"Cover printed on both sides?"}
                                />
                            </div>
                        )}
                    </div>

                    <div>
                        <h4>Add a Cost</h4>
                        <div className='smaller mb-med'>Need to add a cost that applies just to a single print run? Edit the run instead.</div>
                        <TextControl
                            value={newCost.description}
                            change={(value) => {
                                updateObject(newCost, "description", value, setNewCost);
                            }}
                            label={`Description`}
                            valid={false}
                            type="text"
                            bypass={false}
                            description={""}
                        />
                        <NumberControl
                            value={newCost.amount}
                            change={(value) => {
                                updateObject(newCost, "amount", value, setNewCost);
                            }}
                            label={"Amount"}
                            valid={false}
                            min={0}
                            step=".01"
                        />

                        <BinaryControl
                            value={newCost.unit}
                            change={(value) => {
                                updateObject(newCost, "unit", value, setNewCost);
                            }}
                            label={"Is this cost per-unit?"}
                        />

                        <BinaryControl
                            value={newCost.markedUp}
                            change={(value) => {
                                updateObject(newCost, "markedUp", value, setNewCost);
                            }}
                            label={"Should markup apply to this cost?"}
                        />

                        {newCost.amount && newCost.description && <button className='secondary' onClick={addCost}>Add Cost</button>}
                        <hr />
                        <h4>Added Costs</h4>

                        {updatedEdition.additionalCosts && updatedEdition.additionalCosts.map((cost, index) =>
                            <div key={cost.description + index} className='flex sb'>
                                <div>{cost.description} ${cost.amount.toFixed(2)} {cost.unit ? '/unit' : ''}</div><div onClick={() => { removeCost(index) }}>x</div>

                            </div>
                        )}
                        {!updatedEdition.additionalCosts && <span>n/a</span>}
                    </div>

                </div>
                <div className='modal-options'>
                    <button className='primary' onClick={() => { save(updatedEdition) }}>Update and Recalculate</button>
                    <button className='secondary' onClick={() => { close(false) }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}


