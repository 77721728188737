import React, { useEffect, useState } from 'react';

export default function TextAreaControl({ value, change, label = '', help = '', placeholder = '', name = '', valid = true, type = 'text', classlist = '', disabled = false, rows = 3 }) {
  return (
    <div className='text-control'>
      {label && <label for={name} className='form-label'>{label}</label>}
      <textarea id={name} disabled={disabled ? 'disabled' : ''} rows={rows} type={type} className={`${valid && 'valid'} ${classlist}`} placeholder={placeholder} value={value} onChange={(e) => { change(e.target.value) }} />
      {help && <div className='form-help'>{help}</div>}
    </div>
  )
}
