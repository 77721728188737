import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import PaperEditor from './paperedit.js';

export default function AdminPapers({ sessionValid, roles }) {
  const [papers, setPapers] = useState([]);
  const [page, setPage] = useState(0);
  const [per, setPer] = useState(20);
  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);

  const getPapers = async () => {
    const data = await window.CPA.makeRequest('list-all-papers', 'GET');
    if (data) {
      setPapers(Object.values(data));
    }
  }

  document.title = 'Edit Papers | Chatwin Printing'



  useEffect(() => {
    getPapers();
  }, []);

  useEffect(() => {
    if (!editing) {
      getPapers();
    }
  }, [editing,]);

  useEffect(() => {
    if (!adding) {
      getPapers();
    }
  }, [adding,]);

  if (!sessionValid && !roles.admin) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  } else if (!roles.admin) {
    return (<div className='card-container'>You do not have permission to view this page.</div>)
  }

  return (
    <div className='card-container'>
      <div className='page-header'>
        <h1>Paper Stocks</h1>
        <div>
          <button className='primary linkish' onClick={() => { setAdding(true) }}>+ Paper</button>
        </div>
      </div>
      <div className='table-container'>
        <div className='table-header'>
          <div className='flex-2'>Name</div>
          <div className='flex-1'>Type</div>
          <div className='flex-1'>Available?</div>
          <div className='flex-1'>Caliper</div>
          <div className='flex-1'>Cost/Sheet</div>
        </div>
        <div className='table-body'>
          {papers.map((paper) =>
            <div className='table-row' key={paper._id}>
              <div className='flex-2 pseudo-link' onClick={() => { setEditing(paper._id) }}>{paper.name} ({paper.weight})</div>
              <div className='flex-1'>{paper.type.join(', ')}</div>
              <div className='flex-1'>{paper.available ? 'Yes' : 'No'}</div>
              <div className='flex-1'>{paper.caliper}</div>
              <div className='flex-1'>{paper.costPerSheet}</div>
            </div>
          )}
        </div>
      </div>
      {adding && <PaperEditor close={setAdding} />}
      {editing && <PaperEditor close={setEditing} id={editing} />}
    </div>
  )
}
