import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import moment from 'moment';
import { getStatusClass, makeRequest } from '../../Utils.js';
import { ReactComponent as PDF } from '../../icons/pdf.svg';
import { ReactComponent as Book } from '../../icons/book.svg';

export default function AdminKanban({ sessionValid, roles }) {
  const [projects, setProjects] = useState({
    'Ordered': [],
    'Proofs': [],
    'Approved': [],
    'In Production': [],
    'Shipped': [],
    'Ready': [],
  });
  const [dragged, setDragged] = useState('');

  const updateProjectStatus = async (id, status) => {
    let run = await makeRequest(`run?id=${id}`, 'GET');
    let updatedRun = { ...run };
    updatedRun.status = status;
    if (!updatedRun.events) {
      updatedRun.events = [];
    }
    let theevent = {
      type: 'statusChange',
      description: `Project status changed to ${status} by ${window.CPA.user.name}`,
      user: window.CPA.user.id,
      date: new Date().toString()
    }
    updatedRun.events.push(theevent);
    const save = await makeRequest('save-run', 'POST', updatedRun);
    window.CPA.message({ type: 'success', label: 'All Good!', text: 'Project has been updated!', temp: true });
    getProjects();
  }



  const getProjects = async () => {
    const data = await window.CPA.makeRequest('list-all-projects-board', 'GET');
    if (data) {
      let projectData = Object.values(data);
      let newProjects = {
        'Ordered': [],
        'Proofs': [],
        'Approved': [],
        'In Production': [],
        'Ready': [],
      };
      projectData.forEach((proj) => {
        if (newProjects[proj.status]) {
          if (proj.status == 'Ready' && newProjects['Ready'].length < 5) {
            newProjects[proj.status].push(proj);
          } else if (proj.status !== 'Ready') {
            newProjects[proj.status].push(proj);
          }
        }
      })
      setProjects(newProjects);
    }
  }

  document.title = 'Project Board | Chatwin Printing'


  useEffect(() => {
    getProjects();
  }, []);

  if (!sessionValid && !roles.admin) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  } else if (!roles.admin) {
    return (<div className='card-container'>You do not have permission to view this page.</div>)
  }

  return (
    <div>
      <div className='page-header'>
        <h1>Active Projects</h1>
      </div>
      <div className='kanban-board'>
        {Object.keys(projects).map((key) =>
          <div className='kanban-column'>
            <h3 key='key'>{key}</h3>
            {projects[key].map((project) =>
              <div className={`kanban-project
              ${getStatusClass(project.status)}-border`}
                key={project._id}
                draggable={true}
                onDragStart={() => { setDragged(project._id) }}
                onDragEnd={() => { }}
              >
                <div className='smaller mb-xs'><strong>{project.customerData.organization ? project.customerData.organization : project.customerData.name}</strong></div>
                <div><Link to={`/admin/project/${project._id}`}>{project.editionData.title}</Link></div>
                <div className='smaller mt-small'>{moment(project.created).format('LLL')}</div>
              </div>
            )}
            <div
              className='kanban-dropzone'
              onDragOver={(e) => { e.preventDefault() }}
              onDragLeave={(e) => { }}
              onDrop={() => { updateProjectStatus(dragged, key) }}
            >
            </div>
          </div>
        )}
      </div>

    </div>
  )
}
