import TextControl from '../../components/TextControl';
import TextAreaControl from '../../components/TextAreaControl';
import React, { useState, useEffect } from 'react';

export default function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [sent, setSent] = useState(false);

  const sendMessage = async () => {
    if (!email || !name) {
      window.CPA.message({ type: 'error', label: 'error', text: 'Please enter your name and email', temp: true });
      return;
    } else if (!message) {
      window.CPA.message({ type: 'error', label: 'error', text: 'Message cannot be empty.', temp: true });
      return;
    }
    const data = {
      name: name,
      email: email,
      message: message,
      phone: phone
    }
    const request = await window.CPA.makeRequest('send-message', 'POST', data);
    setSent(true);
  }

  if (sent) {
    return (
      <div className='contact-form card-container'>
        <h3>Thanks!</h3>
        <p>We'll get back to you shortly!</p>
      </div>
    )
  }

  return (
    <div className='contact-form card-container'>
      <h3>Get in touch!</h3>
      <p>Have questions about our services and options? We'd love to hear from you! Use the form below to get in touch.</p>
      <TextControl
        value={name}
        change={(value) => {
          setName(value)
        }}
        label={`Your Name`}
        name='name'
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={email}
        change={(value) => {
          setEmail(value)
        }}
        label={`Your Email`}
        name='email'
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
      />

      <TextControl
        value={phone}
        change={(value) => {
          setPhone(value)
        }}
        label={`Your Phone Number`}
        name='phone'
        valid={false}
        type="text"
        bypass={false}
        description={""}
      />

      <TextAreaControl
        value={message}
        change={(value) => {
          setMessage(value)
        }}
        label={`Message`}
        name='message'
        valid={false}
        type="text"
        required={true}
        bypass={false}
        description={""}
        rows={10}
      />

      <button className='primary' onClick={sendMessage}>Submit</button>
    </div>
  )
}