import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import TextControl from '../../components/TextControl';
import NumberControl from '../../components/NumberControl';
import FileControl from '../../components/FileControl';
import moment from 'moment';
import PlaceOrder from './placeOrder';
import Edit from './edit.js';
import Edition from './edition.js';
import Reprint from './reprint.js';
import Payment from './pay.js';
import { getQuote, getSpine, sendEmail, downloadFile, uploadFile, ISODateNow } from '../../Utils.js';

const defaultRun = {
  quantity: 100,
  postalCode: '',
  needShipping: true,
  firstRun: false,
  created: new Date().toString()
}

export default function AccountProjectEdit({ sessionValid, roles }) {
  const [runs, setRuns] = useState([]);
  const [edition, setEdition] = useState({});
  const [customer, setCustomer] = useState({})
  const [placingOrder, setPlacingOrder] = useState(false);
  const [placingOrderId, setPlacingOrderId] = useState('');
  const [selectedRun, setSelectedRun] = useState('');
  const [newQuantity, setNewQuantity] = useState(0);
  const [reprinting, setReprinting] = useState(false);
  const [editingEdition, setEditingEdition] = useState(false);
  const [payingRun, setPayingRun] = useState(-1);
  const [uploading, setUploading] = useState(false);

  document.title = 'Project | Chatwin Printing'


  const { id } = useParams();

  const editionFiles = useRef(null);

  const uploadSelected = async () => {
    setUploading(true);
    window.CPA.message({ type: 'info', label: 'FYI', text: 'Hang tight! We are uploading your files...', temp: true });

    let newFiles = edition.files ? [...edition.files] : [];
    let toUpload = editionFiles.current.files;
    for (let i = 0; i < toUpload.length; i++) {
      let file = await uploadFile(toUpload[i], edition.title);
      if (file.status == 'good') {
        newFiles.push(file);
      }
    }
    setUploading(false);
    window.CPA.message({ type: 'success', label: 'success', text: 'Your files have finished uploading.', temp: true });

    editionFiles.current.value = [];
    let newEdition = { ...edition };
    newEdition.files = newFiles;
    let eddata = await window.CPA.makeRequest('save-edition', 'POST', newEdition);
    getProject();

  }

  const getProject = async () => {
    const data = await window.CPA.makeRequest(`project-user?id=${id}`, 'GET');
    if (data) {
      setRuns(data.runs);
      setCustomer(data.customer);
      setEdition(data.edition);
      document.title = `${data.edition.title} | Chatwin Printing`

    }
  }

  const saveRunData = async (newRun) => {
    newRun.quantity = newQuantity;
    setSelectedRun('');
    let quote = await getQuote(newRun, edition, customer, true);
    let request = await window.CPA.makeRequest('save-run', 'POST', quote);
    getProject();
  }

  const reCalcQuote = async (run) => {
    let quote = await getQuote(run, edition, customer, true);
    let request = await window.CPA.makeRequest('save-run', 'POST', quote);
    getProject();
  }

  const changeStatus = async (status, index) => {
    let therun = { ...runs[index] };
    if (!therun.events) {
      therun.events = [];
    }
    let theevent = {
      type: 'statusChange',
      description: `Project status changed to ${status} by ${window.CPA.user.name}`,
      user: window.CPA.user.id,
      date: new Date().toString()
    }
    therun.events.push(theevent);
    delete therun.editionData;
    delete therun.customerData;
    therun.status = status;
    let request = await window.CPA.makeRequest('save-run', 'POST', therun);
    let message = '';
    let subject = '';
    if (status == 'Approved') {
      subject = `${window.CPA.user.name} has approved proofs for ${edition.title}.`;
      message = `Project proofs approved for production by ${window.CPA.user.name} for print run of <a href='https://chatwinprinting.com/admin/project/${therun._id}'>${edition.title}</a>`;
    }
    sendEmail(null, message, subject)
    getProject();
  }

  const saveNewRunData = async (newRun) => {
    newRun.edition = edition._id;
    newRun.customer = customer._id;
    newRun.status = 'Quote';
    newRun.additionalCosts = [];
    if (!newRun.events) {
      newRun.events = [];
    }
    newRun.events.push({
      type: 'statusChange',
      description: `Reprint project created by ${window.CPA.user.name}`,
      user: window.CPA.user.id,
      date: new Date().toString()
    })
    let request = await window.CPA.makeRequest('save-run', 'POST', newRun);
    getProject();
    window.CPA.message({ type: 'success', label: 'success', text: 'Your quote is all good!', temp: true });
    setReprinting(false);
  }

  const saveEditionData = async (newEdition) => {

    if (newEdition.type == 'Paperback') {
      newEdition.spine = await getSpine(newEdition);
    }
    let ed = await window.CPA.makeRequest('save-edition', 'POST', newEdition);
    // Recalculate open runs
    for (let i = 0; i < runs.length; i++) {
      if (runs[i].status == 'Quote') {
        let quote = await getQuote(runs[i], newEdition, customer);
        let request = await window.CPA.makeRequest('save-run', 'POST', quote);
      }
    }
    //
    setEditingEdition(false);
    getProject();
  }

  const isQuoteGood = (dateQuoted, quoteExpires) => {
    const d1 = new Date();
    const d2 = new Date(quoteExpires);
    return d2 > d1;
  }

  const canGetReprint = () => {
    if (!runs) {
      return false;
    }

    for (let i = 0; i < runs.length; i++) {
      if (runs[i].status !== 'Quote' && runs[i].status !== 'Needs Review') {
        return true;
      }
    }
    return false;
  }

  const placeAdminOrder = async (thisRun) => {
    let newRun = { ...thisRun };
    newRun.status = 'Ordered';
    newRun.admin = true;
    newRun.proofType = 'printedInPerson';
    newRun.needShipping = false;
    newRun.paid = true;
    newRun.dateOrdered = ISODateNow();
    if (!newRun.events) {
      newRun.events = [];
    }
    newRun.events.push({
      type: 'statusChange',
      description: `${window.CPA.user.name} has changed the status to Ordered`,
      user: window.CPA.user.id,
      date: new Date().toString()
    });
    let rundata = await window.CPA.makeRequest('save-run', 'POST', newRun);
    getProject();
    let email = {
      subject: `New order from ${customer.name}.`,
      message: `Hey Team, We have a new order from ${customer.organization ? customer.organization : customer.name}. You can view the print project here:<br><br>Love,<br>The Chatwin Printing Bot`
    }
    window.CPA.makeRequest('send-email', 'POST', email);
  }


  useEffect(() => {
    getProject();
  }, []);

  if (!edition._id) {
    return (<div>This project may not exist.</div>)
  }

  if (!sessionValid) {
    return (<div className='card-container'>You need to <Link to='/login'>login</Link> to view this page.</div>)
  }

  return (
    <div className='card-container'>
      <div className='page-header'>
        <div>
          <div>Created on {moment(edition.created).format('LLL')}</div>
          <h1>{edition.title}</h1>
          {edition.author && <div>by {edition.author}</div>}
        </div>
      </div>

      <div className='flex sb'>
        <h3>Edition Details</h3>
        {!canGetReprint() && <a onClick={() => { setEditingEdition(true) }}>Edit</a>}
      </div>
      <div className='table-row'>
        <div className='flex-2'>Project Type</div>
        <div className='flex-1'>{edition.type}</div>
      </div>
      <div className='table-row'>
        <div className='flex-2'>Trim</div>
        <div className='flex-1'>{edition.trim}" {edition.type == 'Paperback' && <span>{`(w/ ${edition.spine}" spine)`}</span>}</div>
      </div>
      <div className='table-row'>
        <div className='flex-2'>Page Count</div>
        <div className='flex-1'>{edition.pages}</div>
      </div>
      {edition.type == 'Flat' &&
        <div>
          <div className='table-row'>
            <div className='flex-2'>Printing</div>
            <div className='flex-1'>{edition.flatPrinting == 'blackAndWhite' ? 'Black & White' : 'Full Color'}  {edition.flatTwoSided ? ', two-sided, ' : ''} on {edition.flatPaperData.weight} {edition.flatPaperData.name}</div>
          </div>

          <div className='table-row'>
            <div className='flex-2'>Lamination</div>
            <div className='flex-1'>{edition.lamination}</div>
          </div>

          <div className='table-row'>
            <div className='flex-2'>Collation?</div>
            <div className='flex-1'>{edition.flatCollated ? 'Yes' : 'No'}</div>
          </div>

        </div>

      }
      {edition.type == 'Paperback' && <div>
        <div className='table-row'>
          <div className='flex-2'>Binding</div>
          <div className='flex-1'>{edition.binding}</div>
        </div>
        <div className='table-row'>
          <div className='flex-2'>Interior Printing</div>
          <div className='flex-1'>
            {edition.interiorPrinting == 'blackAndWhite' ? 'Black & White' : 'Full Color'} on {edition.interiorPaperData.weight} {edition.interiorPaperData.name}
          </div>
        </div>
        <div className='table-row'>
          <div className='flex-2'>Cover Printing</div>
          <div className='flex-1'>
            Full-color {edition.coverTwoSided ? 'two-sided' : 'one-sided'} on {edition.coverPaperData.weight} {edition.coverPaperData.name}
          </div>
        </div>
        <div className='table-row'>
          <div className='flex-2'>Cover Lamination</div>
          <div className='flex-1'>{edition.lamination}</div>
        </div>
      </div>}


      <div className='flex sb mt-large'><h3>Files</h3></div>
      {(!edition.files || edition.files.length == 0) && <div className='mb-med'><em>No files have been uploaded yet.</em></div>}

      {edition.files && edition.files.map((file) =>
        <div className='table-row' key={file.file}>
          <div className='flex-2'>{file.name}</div>
          <div className='flex-1'><a onClick={() => { downloadFile(file.file, true) }}>Download</a></div>
        </div>
      )}
      <FileControl
        classlist=''
        help={''}
        valid={false}
        reference={editionFiles}
        multiple={true}
        disabled={uploading}
        image={true}
        accept="image/png, image/jpeg, application/pdf"
      />

      <button className='primary' disabled={uploading} onClick={uploadSelected}>Upload</button>

      <div className='flex sb mt-large'><h3>Print Runs</h3> {canGetReprint() && <button className='primary linkish' onClick={setReprinting}>Reprint Quote</button>}</div>

      <div className='table-container'>
        <div className='table-header'>
          <div className='flex-1'>Status</div>
          <div className='flex-1'>Quantity</div>
          <div className='flex-2'>Cost</div>
          <div className='flex-2'>Actions</div>
        </div>
        <div className='table-body'>
          {edition.runs.map((run, index) =>
            <div className='table-row' key={run._id}>
              <div className='flex-1'>
                <div>{run.status}</div>
                {run.status == 'Quote' && <div className='smaller mt-xs'>
                  <div>Quoted {moment(run.dateQuoted).format('LLL')}</div>
                  <div className={`${isQuoteGood(run.dateQuoted, run.quoteExpires) ? '' : 'cancel'}`}>Good until {moment(run.quoteExpires).format('LLL')}</div>
                </div>}
              </div>
              <div className='flex-1'>
                {selectedRun == run._id ?
                  <div>
                    <NumberControl
                      value={newQuantity}
                      classlist='table'
                      change={setNewQuantity}
                      valid={false}
                      step="1"
                    />
                    <div className='flex smaller gap-med'>
                      <a onClick={() => { saveRunData(run) }}>Update</a>
                      <a onClick={() => { setSelectedRun('') }}>Cancel</a>
                    </div>
                  </div> :
                  <span>{run.quantity}</span>
                }
              </div>
              <div className='flex-2'>
                <div>${run.total.toFixed(2)}{run.needsReview ? '*' : ''}</div>
                <div className='smaller mt-xs'>Subtotal ${run.subtotal.toFixed(2)} at ${(run.subtotal / run.quantity).toFixed(2)} / unit</div>
                {run.proofCost ? <div className='smaller mt-xs'>Proof: ${run.proofCost.toFixed(2)}</div> : ''}
                {run.proofShipping ? <div className='smaller mt-xs'>Proof Shipping: ${run.proofShipping.toFixed(2)}</div> : ''}
                {run.shipping ? <div className='smaller mt-xs'>Shipping: ${run.shipping.toFixed(2)}</div> : ''}
              </div>
              <div className='flex-2' style={{ gap: '12px' }}>
                {run.needsReview && <div className='smaller'>*Some options you requested require review by our team and may affect the quoted price. We'll be in touch shortly!</div>}
                <div className='flex gap-small'>
                  {run.status == 'Quote' && !run.needsReview && <button className='secondary linkish' disabled={selectedRun == run._id} onClick={() => {
                    setSelectedRun(run._id);
                    setNewQuantity(run.quantity);
                  }}>Edit Quantity</button>}
                  {run.status == 'Quote' && !run.needsReview && isQuoteGood(run.dateQuoted, run.quoteExpires) && !customer.roles.admin && <button className='primary linkish' onClick={() => {
                    setPlacingOrderId(run._id);
                    setPlacingOrder(true);
                  }}>Place Order</button>}
                  {run.status == 'Quote' && !run.needsReview && isQuoteGood(run.dateQuoted, run.quoteExpires) && customer.roles.admin && <button className='primary linkish' onClick={() => { placeAdminOrder(run) }}>Place Order (Admin)</button>

                  }
                  {run.status == 'Quote' && !run.needsReview && !isQuoteGood(run.dateQuoted, run.quoteExpires) && <button className='primary linkish' onClick={() => {
                    reCalcQuote(run);
                  }}>Recalculate</button>}
                </div>
                {run.status == 'Proofs' && <button className='secondary linkish' onClick={() => { changeStatus('Approved', index) }}>Approve Proofs</button>}
                {run.total > run.amountPaid &&
                  <button className='primary linkish' onClick={() => { setPayingRun(index) }}>Pay Balance of ${(run.total - run.amountPaid).toFixed(2)}</button>
                }
              </div>
            </div>
          )}
        </div>
      </div>

      {placingOrder && <PlaceOrder hasFiles={edition.files && edition.files.length > 0} getProject={getProject} customer={customer} run={placingOrderId} edition={edition} close={setPlacingOrder}></PlaceOrder>}
      {editingEdition && <Edition edition={edition} save={saveEditionData} close={setEditingEdition} />}
      {reprinting && <Reprint run={defaultRun} edition={edition} save={saveNewRunData} close={setReprinting} customer={customer} />}
      {payingRun >= 0 && <Payment getProject={getProject} run={runs[payingRun]} edition={edition} close={setPayingRun} />}
    </div>
  )
}
